import React from 'react'
import { Route, Switch} from "react-router-dom";
import Home from '../component/home/Home';
import WatchingVideo from '../component/watching/WatchingVideo'
import UserSub from '../component/userSub/UserSub'
import VideosLike from '../component/videosLike/VideosLike';
import User from '../component/user/User';
import ManageUser from '../component/manageUser/ManageUser';
import Login from '../component/login/Login';
import VideosSearch from '../component/videosSearch/VideosSearch';
import VideosWatched from '../component/videoWatched/VideoWatched';
import FeedBack from '../component/feedback/FeedBack';
import VideoList from '../component/VideoList/VideoList';
import SubscribePackage from '../component/subscribePackage/SubscribePackage'; 
import TermsOfService from '../component/feedback/TermsOfService';

export default function DieuHuongUrl() {
    const routes = [
        {
          path: "/",
          exact: true,
          main: () => <Home/>
        },
        {
          path: "/watching/:id",
          main: () => <WatchingVideo/>
        },
        
        {
            path: "/userFollow",
            main: () => <UserSub/>
        },
        {
            path: "/videosLike",
            main: () => <VideosLike/>
        },
        {
            path: "/videosWatched",
            main: () => <VideosWatched/>
        },
        {
            path: "/user/:idUser",
            main: () => <User/>
        },
        {
            path: "/manageUser",
            main: () => <ManageUser/>
        },
        {
            path: "/login",
            main: () => <Login/>
        },
        {
            path: "/videosSearch/:filter",
            main: () => <VideosSearch/>
        },
        {
            path: "/feedback",
            main: () => <FeedBack/>
        }
        ,
        {
            path: "/videolist/:idListVideo",
            main: () => <VideoList/>
        }
        ,
        {
            path: "/subscribePackage", // Đường dẫn mới
            main: () => <SubscribePackage/> // Thành phần mới
        }
        ,
        {
            path: "/termsOfService",
            main: () => <TermsOfService/>
        }
      ];
      
        return (  
                <Switch>
                    {routes.map((route, index) => (
                            <Route 
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                children={<route.main />}
                            />
                    ))}
                </Switch>
        )
    }

