import React ,{useState, useEffect} from 'react'
import HomeLeft from '../home/home_left/HomeLeft'
import { useQuery } from '@apollo/client';
import {GET_AVATAR_USER_MANAGE} from '../../graphql-client/Queries';
import moment from 'moment';
import { useAuthContext } from '../../context/AuthContext';
import NotificationError from '../NotificationError';
// import { user } from '../../../../server/src/resolver/Query';
export default function SubscribePackage() {
    const {isAuthenticated} = useAuthContext();
    
     // State để lưu thời gian học và dung lượng sử dụng
     const [timeWatched, setTimeWatched] = useState(0);
     const [uploadSize, setUploadSize] = useState(0);
    
     // Fetch dữ liệu từ GraphQL
     const { loading, error, data } = useQuery(GET_AVATAR_USER_MANAGE);
 
     // Khi dữ liệu từ GraphQL thay đổi, cập nhật state
     useEffect(() => {
         if (data && data.userManage) {
             setTimeWatched(data.userManage.monthlyWatchTime);
             setUploadSize(data.userManage.monthlyUploadSize);
         }
     }, [data]); // Chạy lại khi `data` thay đổi
     if(!isAuthenticated) return (
        <NotificationError text={'Hãy đăng nhập hoặc đăng ký để có thể tiếp tục.'}></NotificationError>
    )
     if (loading) return <p>Loading...</p>;
    if (error) return <p>{error.message}</p>;
    const userManage = data.userManage;
    console.log(userManage.new_id);
    
    const handlePurchase = async ( price,description) => {
        
        const response = await fetch(process.env.REACT_APP_URL_WEB_SERVER_ROUTER_CREATE_PAYMENT_LINK, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                amount: price, // Giá sản phẩm
                description:description, // Mô tả sản phẩm
                returnUrl: process.env.REACT_APP_URL_ROUTER_CLIENT_SUBSCRIPTION,
                cancelUrl: process.env.REACT_APP_URL_ROUTER_CLIENT_SUBSCRIPTION,
                
                buyer: {
                    name: userManage.username,
                    email: userManage.email,
                    id: userManage.id,
                },
            }),
        });
    
        const data = await response.json();
        if (data.checkoutUrl) {
            window.open(data.checkoutUrl, "_blank");
        } else {
            console.error('Lỗi khi tạo thanh toán:', data.error);
        }
    };
    
    const formatDurationText=(seconds) =>{
        const duration = moment.duration(seconds, 'seconds');
        const hours = duration.hours();
        const minutes = duration.minutes();
        
        if (hours > 0) {
            return `${hours} giờ ${minutes} phút`;
        } else {
            return `${minutes} phút`;
        }
    }
    function convertMBtoGB(mb) {
        return (mb / 1024).toFixed(2); // Giữ 2 chữ số sau dấu phẩy
    }
    return (
        <div className='row'>
            <HomeLeft />
            <div className='col-xxl-10 col-xl-10 col-lg-10 col-mb-10 col-sm-12'>
                <div className="container alert alert-info text-center mt-3">
                    <h5>Thông tin sử dụng</h5>
                    <p>⏳ Thời gian xem video còn lại của <strong>{userManage.username}</strong> trong tháng: <strong>{formatDurationText(timeWatched)} </strong></p>
                    <p>📁 Dung lượng video tải lên chưa sử dụng của <strong>{userManage.username}</strong> trong tháng: <strong>{convertMBtoGB(uploadSize)} GB</strong></p>
                </div>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card mb-4">
                            
                                <div className="card-body">
                                <h5 className="card-title">Mua thêm thời lượng khóa học</h5>
                                    <p className="card-text">Thêm 10 giờ xem video bài giảng/ tháng trên nền tảng Linklearn.vn: chi phí 60.000 vnđ</p>
                                    <button 
                                        className="btn btn-primary"
                                        onClick={() => handlePurchase( 60000,'TIME')}//60000 VND
                                    >
                                        Mua ngay
                                    </button>
                                </div>
                                <div className="card-body">
                                <h5 className="card-title">Mua thêm thời lượng khóa học</h5>
                                    <p className="card-text">Thêm 20 giờ xem video bài giảng/ tháng trên nền tảng Linklearn.vn: chi phí 100.000 vnđ</p>
                                    <button 
                                        className="btn btn-primary"
                                        onClick={() => handlePurchase(100000,'TIME')} //100000 VND
                                    >
                                        Mua ngay
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">Mua thêm dung lượng video</h5>
                                    <p className="card-text">Thêm 10GB dung lượng video tải lên/ tháng trên nền tảng Linklearn.vn: chi phí 70.000 vnđ</p>
                                    <button 
                                        className="btn btn-primary"
                                        onClick={() => handlePurchase(70000,'DATA')}//70000 VND
                                    >
                                        Mua ngay
                                    </button>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">Mua thêm dung lượng video</h5>
                                    <p className="card-text">Thêm 20GB dung lượng video tải lên/ tháng trên nền tảng Linklearn.vn: chi phí 120.000 vnđ</p>
                                    <button 
                                        className="btn btn-primary"
                                        onClick={() => handlePurchase(120000,'DATA')}//120000 VND
                                    >
                                        Mua ngay
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}