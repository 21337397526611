import WatchingVideoLeftBot from './wVideo_left_bot/WVideoLeftBot';
import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import { useAuthContext } from '../../../context/AuthContext';
import { ConvertToCloudFrontUrl } from '../../../utils/ConvertToCloudFrontUrl';
import { PUBLISH_USER } from '../../../graphql-client/Mutation';
import { useMutation} from '@apollo/client';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default React.memo(function WatchingVideoLeft(props) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const sessionWatchTime = useRef(0); // Tổng thời gian thực tế người dùng đã xem trong phiên
  const watchStartTime = useRef(null); // Thời điểm bắt đầu xem video
  const [watchedTime, setWatchedTime] = useState(0); 
  const {isAuthenticated} = useAuthContext() 

  const [updateMonthlyWatchTime] = useMutation(PUBLISH_USER);
  
  const urlVideoConverted = ConvertToCloudFrontUrl(props.urlVideo);
  
  useEffect(() => {
    
    if (!playerRef.current && videoRef.current ) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: true,
        preload: 'auto',
        playbackRates: [0.5, 1, 1.5, 2],
      });
      
      playerRef.current.src({
        src: urlVideoConverted,
        type: 'video/mp4',
      });

      // Lắng nghe sự kiện video
      playerRef.current.on('play', handlePlay);
      playerRef.current.on('pause', handleUpdateWatchTime);
      playerRef.current.on('ended', handleUpdateWatchTime);
      playerRef.current.on('dispose', handleUpdateWatchTime);
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.off('play', handlePlay);
        playerRef.current.off('pause', handleUpdateWatchTime);
        playerRef.current.off('ended', handleUpdateWatchTime);
        // playerRef.current.pause(); // Dừng video trước khi hủy
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [props.urlVideo]);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        handleUpdateWatchTime(); // Cập nhật thời gian khi người dùng rời tab
      }
    };
  
    const handleBeforeUnload = () => {
      handleUpdateWatchTime(); // Cập nhật khi người dùng rời trang
    };
  
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  // Khi video bắt đầu phát, ghi lại thời gian bắt đầu xem
  const handlePlay = () => {
    watchStartTime.current = Date.now(); // Ghi lại thời gian bắt đầu xem
  };

  // Khi video dừng hoặc kết thúc, cập nhật thời gian xem
  const handleUpdateWatchTime = () => {
    if (watchStartTime.current) {
      const watchedDuration = (Date.now() - watchStartTime.current) / 1000; // Tính thời gian xem (giây)
      sessionWatchTime.current += watchedDuration; // Cộng dồn thời gian đã xem
      watchStartTime.current = null; // Reset thời gian bắt đầu

      setWatchedTime((prevTime) => {
        const totalTime = prevTime + watchedDuration;
        // console.log(`Thời lượng đã xem: ${watchedDuration} sec`);
        // console.log(`Total Thời lượng đã xem: ${Math.round(totalTime)} sec`);
        updateMonthlyWatchTime({ variables: { monthlyWatchTime: Math.round(totalTime) } });
        return totalTime;
      });
    }
  };
  const formatDurationText = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();
    const secs = duration.seconds();

    if (hours > 0) {
        return `${hours} giờ ${minutes} phút ${secs} giây`;
    } else if (minutes > 0) {
        return `${minutes} phút ${secs} giây`;
    } else {
        return `${secs} giây`;
    }
};

  return (
    <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 mx-auto card WatchingVideoLeft d-flex flex-column">
      {isAuthenticated && props?.isMonthlyWatchTime > 0
        ?
        <>
          {urlVideoConverted==='/default-avatar.png'?
          <div className="  alert alert-dark text-center mt-3 p-3 ">
            Không có video
          </div>
          :(
          <>
          <video
          id="my-video"
          className="video-js vjs-default-skin videoPlay mx-auto mt-2 p-2"
          ref={videoRef}
          autoPlay
          />
          <div className="col-11 mx-auto">
            <div className="alert alert-success p-2 m-1" role="alert">
              Thời lượng đã xem trong phiên xem: {formatDurationText(watchedTime)}
              <br />
              Nếu sảy ra tình trạng giật lag hãy dừng video lại vài giây rồi tiếp tục phát video nhé!
            </div>
          </div>
          </>)}
        </>
          
        : (
        <div className="alert alert-warning text-center mt-3">
          Bạn đã hết thời lượng xem video trong tháng hoặc chưa đăng nhập. Hãy{" "}
          <Link to="/subscribePackage">đăng ký gói cước</Link> hoặc{" "}
          <Link to="/login">đăng nhập</Link> để tiếp tục xem video. Hoặc xem tài liệu của video
        </div>
          )}
        <WatchingVideoLeftBot />
    </div>
  );
});

