import React from 'react';
import { uploadImageToS3 } from './UploadImgToS3';
import 'react-image-crop/dist/ReactCrop.css';
import { GET_AVATAR_USER_MANAGE } from '../graphql-client/Queries';
import { useQuery } from '@apollo/client';
import { ConvertToCloudFrontUrl } from '../utils/ConvertToCloudFrontUrl.js'
export default function ComponentUploadImgToS3({ formListVideo, setFormListVideo }) {
  const {data} = useQuery(GET_AVATAR_USER_MANAGE)
      const userId = data && data.userManage && data.userManage.id
      
  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    
    // Kiểm tra nếu có tệp được chọn và tệp có loại hợp lệ không
    if (selectedFile) {
      if (!['image/jpeg', 'image/png'].includes(selectedFile.type)) {
        alert('Vui lòng chọn tệp JPEG hoặc PNG.');
        e.target.value = ''; // Xóa tệp đã chọn
        return; // Dừng hàm nếu tệp không hợp lệ
      }
  
      const reader = new FileReader();
      reader.onload = () => {
        // Tạo một đối tượng file mới để truyền vào hàm tải lên
        const file = new File([selectedFile], 'image.jpg', { type: selectedFile.type });
  
        // Gọi hàm tải lên
        uploadImageToS3(file, formListVideo, setFormListVideo, userId);
      };
  
      reader.readAsDataURL(selectedFile);
    }
  };
  

  return (
    <div className='p-2'>
      {!formListVideo.thumbnail ? (
        <div className="d-flex justify-content-between ">
          <input type="file" accept="image/jpeg, image/png, image/jpg" onChange={handleFileSelect} multiple={false} />
        </div>
      ) : (
        formListVideo.thumbnail && (
          <div className="d-flex justify-content-start">
            <p className='me-3'>Đã tải ảnh lên</p>
            <img src={ConvertToCloudFrontUrl(formListVideo.thumbnail)} alt="uploaded" className="thumbnailOnForm" />
          </div>
        )
      )}
    </div>
  );
}
