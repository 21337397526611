
import { NavLink } from 'react-router-dom'
import React from 'react'

export default function HomeLeft() {
    return (
        <div className="col-xxl-2 col-xl-2 col-lg-2 col-mb-2  sideBar ">
            <div className="navigation">
                <ul>
                    <li>
                    <NavLink to="/">
                        <span className="Icon ps-3"><i className="fas fa-home" /></span>
                        <span className="title">Trang chủ</span>
                    </NavLink>
                    </li>
                    
                    <li>
                    <NavLink to="/manageUser">
                        <span className="Icon ps-3 "><i className="fas fa-user-tie" /></span>
                        <span className="title">Kênh của bạn</span>
                    </NavLink>
                    </li>
                    
                    <li>
                    <NavLink to="/videosWatched">
                        <span className="Icon ps-3 "><i className="fas fa-hourglass-start" /></span>
                        <span className="title">Đã xem gần đây</span>
                    </NavLink>
                    </li>

                    <li>
                    <NavLink to="/videosLike">
                        <span className="Icon ps-3 "><i className="fas fa-book-open" /></span>
                        <span className="title">Học liệu yêu thích</span>
                    </NavLink>
                    </li>

                    <li>
                    <NavLink to="/userFollow">
                        <span className="Icon ps-3 "><i className="fas fa-calendar-check" /></span>
                        <span className="title">Kênh đă đăng ký</span>
                    </NavLink>
                    </li>

                    <li>
                    <NavLink to="/subscribePackage">
                        <span className="Icon ps-3 "><i className="fas fa-file-signature" /></span>
                        <span className="title">Đăng ký gói cước</span>
                    </NavLink>
                    </li>
                </ul>
            </div> 
        </div>
    )
}

