import React,{useState} from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import { LIST_VIDEO_USER } from '../../../../graphql-client/Queries'
import VideoCard from '../../../home/home_right/videoCard/VideoCard'
import ShowVideoInListVideo from '../../../manageUser/bodyManageUser/manageListVideo/ShowVideoInListVideo'
import NotificationError from '../../../NotificationError'
import { ConvertToCloudFrontUrl } from '../../../../utils/ConvertToCloudFrontUrl'
import { SHOW_VIDEO_LIST } from '../../../../graphql-client/Queries';
import { Link } from "react-router-dom";

import VideoInListVideo from './VideoInListVideo'
export default function VideoListUser() {
    let {idUser} = useParams();
    const  [formShowVideo, setFormShowVideo] = useState({
        idVideoListShow: null
    })
    const upTakeOnFetchMore = 4;
    const beginTake = 4;
    const [buttonStatus, setButtonStatus] = useState({
        isCountListVideoDoClick: beginTake,// bằng với số take của data ban đầu
        showButtonNewVideo: false,
        
    })
    const handleShowFormShowVideoInVideoList =  (idVideoList)=>{
        setFormShowVideo({
            idVideoListShow: idVideoList
        })
      }
    
    const{loading, error, data, fetchMore}= useQuery(LIST_VIDEO_USER,{
        variables: {idUser: idUser, take: beginTake},
    });
    if(loading) return <p>loading...</p>;
    if(error) return <p>Error! {error.message}</p>;
    const onLoadMore = () => {
        fetchMore({
        variables: {
            idUser: idUser,
            take:data.listVideoUser.length+upTakeOnFetchMore
        },
    })}
    const videoListUser = data.listVideoUser
    
    if(data.listVideoUser.length===0) return <NotificationError text={"Chưa có Khóa Học nào."}></NotificationError>
    return (
        <div >
            <br />
                <div 
                    className={"modal fade"}
                    id={formShowVideo.idVideoListShow 
                        ? "showVideoInVideoListManager" + formShowVideo.idVideoListShow
                        : "showVideoInVideoListManager-default"
                        }  
                    tabIndex={-1} 
                    aria-labelledby={"showVideoInVideoListManagerLabel"+formShowVideo.idVideoListShow} 
                    aria-hidden="true"
                 >
                    <div className="modal-dialog fix-maxWidth">
                        {
                            formShowVideo.idVideoListShow &&
                            <ShowVideoInListVideo 
                                key={formShowVideo.idVideoListShow}
                                idVideoList={formShowVideo.idVideoListShow}
                                user={true}
                                setFormShowVideo={setFormShowVideo}
                            >
                            </ShowVideoInListVideo>
                        }
                    </div>
                </div>
                <div 
                    id="showVideoInVideoListManager-default" 
                    className="modal fade" 
                    tabIndex="-1" 
                    role="dialog"
                    // aria-labelledby={"showVideoInVideoListManager-default"}
                    >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <p>không có video</p>
                        </div>
                    </div>
                </div>

                {
                    videoListUser.map((videoList) =>
                        {
                            return (
                                <div key ={videoList.id+'no2'} >
                                    {/* <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 me-auto" > */}
                                        <div className="card m-3 p-1 ">
                                            <div className="row g-0">
                                                <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12">
                                                    <img
                                                    className="imgListVideo m-2" 
                                                     alt={'ảnh đại diện khóa học '+videoList.title}
                                                     src={
                                                        videoList.thumbnail?
                                                        ConvertToCloudFrontUrl(videoList.thumbnail)
                                                        :'/default-image.jpg'
                                                     }
                                                    />
                                                </div>
                                                <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12">
                                                    <div className="card-body">
                                                        <h5 className="card-title text_per_line">
                                                            {videoList.title}
                                                        </h5>
                                                        
                                                        <p className="card-text text_per_line">
                                                            {videoList.about}
                                                        </p>
                                                        
                                                        <div className="statisticalVideo d-flex">
                                                            <div className="d-flex">
                                                                <p>
                                                                    {videoList.countVideo}
                                                                </p>
                                                                &nbsp;
                                                                <p>video</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {videoList.countVideo>0 &&
                                            <div className='mb-2 ms-2 mt-2 '>
                                                <button  className="btn ms-2" data-bs-toggle="collapse" aria-expanded="false"
                                                        data-bs-target={"#listVideo" + videoList.id +"no3" }  aria-controls="collapseExample" >
                                                        <b>
                                                            Danh sách học liệu
                                                        </b>
                                                </button>
                                                {/* <Link to = {"/videoList/" + videoList.id}>
                                                    <b>Xem tất cả video</b>
                                                </Link> */}
                                            </div>}
                                        </div>
                                    {/* </div> */}
                                    <div className="collapse" id={"listVideo" + videoList.id +"no3"}>
                                        <div className="row backgroundShow m-3">
                                            
                                            {videoList.video.length ===0
                                            ?<NotificationError text={"Chưa có Video nào."}></NotificationError>
                                            :
                                            // <VideoInListVideo key={videoList.id+'no1'} idVideoList={videoList.id} ></VideoInListVideo>
                                            videoList.video.map( (video)=>
                                                {
                                                return (
                                                        <VideoCard
                                                            key={video.id+'videoList2'}
                                                            publishVideoManager={false}
                                                            idUser={idUser}
                                                            document={video.document}
                                                            description={video.description}
                                                            title ={video.title}
                                                            tags ={video.tags}
                                                            date={video.createdAt}
                                                            view={video.countView}
                                                            like={video.countLike}
                                                            videoId={video.id}
                                                            thumbnail={video.thumbnail}
                                                        >
                                                        </VideoCard>
                                                        )
                                                }
                                            )
                                            
                                            }
                                        {/* {
                                        videoList.video.length >=6 &&
                                            <button className=' btn ' 
                                                onClick={()=>handleShowFormShowVideoInVideoList(videoList.id)}
                                                data-bs-toggle="modal" 
                                                data-bs-target={
                                                    formShowVideo.idVideoListShow 
                                                    ? "#showVideoInVideoListManager" + formShowVideo.idVideoListShow
                                                    : "#showVideoInVideoListManager-default"
                                                } 
                                                type="button"
                                            >   
                                                <b>
                                                    Xem tất cả Video
                                                </b>
                                            </button>
                                        } */}
                                        </div>
                                    </div>
                                    <br />
                                </div>  
                                )
                        }
                    )
                }
            {
              videoListUser.length===buttonStatus.isCountListVideoDoClick && 
                <button className=' btn ms-2' 
                onClick={()=>{
                    setButtonStatus({isCountListVideoDoClick: buttonStatus.isCountListVideoDoClick+upTakeOnFetchMore})// cộng :5 băng với số dữ liệu được lấy thêm 
                    onLoadMore()
                }}
                > 
                <b>
                    Xem thêm khóa học
                </b>
                </button>
            }
            <hr />
        </div>

    )
}
