import React, { useState, useContext, useCallback } from "react";
import JWTManager from '../utils/jwt'; // Import đối tượng JWTManager để quản lý JWT token
import GET_AVATAR_USER_MANAGE from '../graphql-client/Queries'; // Import truy vấn để lấy thông tin người dùng từ GraphQL
const defaultIsAuthenticated = false; // Giá trị mặc định cho trạng thái xác thực
// Tạo ngữ cảnh (context) để quản lý trạng thái xác thực
export const AuthContext = React.createContext({
    isAuthenticated: defaultIsAuthenticated, // Biến lưu trạng thái xác thực (đã đăng nhập hay chưa)
    setIsAuthenticated: () => {}, // Hàm đặt lại trạng thái xác thực (mặc định là hàm trống)
    checkAuth: () => Promise.resolve(), // Hàm kiểm tra xác thực (mặc định trả về một Promise đã hoàn thành)
    logoutClient: () => {}, // Hàm để đăng xuất người dùng (mặc định là hàm trống)
    userId: null, // Thêm userId vào context
});

// Hook tùy chỉnh để dễ dàng truy cập AuthContext trong các component khác
export const useAuthContext = () => useContext(AuthContext);

// Component Provider cung cấp AuthContext cho các thành phần con
const AuthContextProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(defaultIsAuthenticated); // Trạng thái xác thực (mặc định là false)
    const [userId, setUserId] = useState(null); // Thêm state userId
    // Hàm kiểm tra xác thực, dùng useCallback để tối ưu hóa hiệu suất
    const checkAuth = useCallback(
        async () => {
            const token = JWTManager.getToken(); // Lấy token từ JWTManager

            if (token) {
                setIsAuthenticated(true); // Nếu có token, đặt trạng thái là đã xác thực
                setUserId(JWTManager.getUserId()); // Cập nhật userId
                }
            else {
                const success = await JWTManager.getRefreshToken(); // Nếu không có token, cố gắng lấy token mới (refresh token)
                if (success) {
                    setIsAuthenticated(true); // Nếu thành công lấy token mới, đặt trạng thái là đã xác thực
                    setUserId(JWTManager.getUserId()); // Cập nhật userId
                }
                
            }
        },
        [], // useCallback sẽ không thay đổi trừ khi có sự thay đổi trong dependencies (ở đây là mảng rỗng)
    );

    // Hàm để đăng xuất người dùng
    const logoutClient = () => {
        JWTManager.deleteToken(); // Xóa token khỏi JWTManager
        setIsAuthenticated(false); // Đặt trạng thái là chưa xác thực
        setUserId(null); // Xóa userId khi đăng xuất
    };

    // Dữ liệu và hàm được cung cấp cho AuthContext
    const authContextData = {
        isAuthenticated,        // Trạng thái đã đăng nhập hay chưa
        setIsAuthenticated,     // Hàm để thay đổi trạng thái xác thực
        checkAuth,              // Hàm kiểm tra xác thực
        logoutClient,            // Hàm đăng xuất người dùng
        userId,                 // Thêm userId vào context
    };

    // Trả về Provider của AuthContext, bao bọc các component con và cung cấp dữ liệu qua context
    return (
        <AuthContext.Provider value={authContextData}>
            {children} {/* Hiển thị các component con bên trong Provider */}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
