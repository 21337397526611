
import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
// import { GET_AVATAR_USER_MANAGE } from '../../../../../graphql-client/Queries';
import { PUBLISH_USER } from '../../../../../graphql-client/Mutation';
import MakeImg from '../../../../../uploadToS3/MakeImg';
import { uploadImageToS3 } from '../../../../../uploadToS3/UploadImgToS3';
import { ConvertToCloudFrontUrl } from '../../../../../utils/ConvertToCloudFrontUrl';
export default React.memo(function AvatarForm(props) {
  const [avatarForm, setAvatarForm] = useState({ thumbnail: '' });
  const [croppedImage, setCroppedImage] = useState(null);
  const userId = props.userId
  const [settingUser] = useMutation(PUBLISH_USER, {
    variables: {
      avatar: avatarForm.thumbnail,
    },
  });
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    await uploadImageToS3(croppedImage, avatarForm, setAvatarForm, userId)
    settingUser();
    setCroppedImage(null);
    setAvatarForm({ thumbnail: ''})
  };
  return (
      <div>
        <h5>Ảnh đại diện</h5>
        <div className="form-group d-flex flex-column m-2">
              <MakeImg 
                  croppedImage={croppedImage} setCroppedImage={setCroppedImage}
              >
              </MakeImg>
              {croppedImage && (
                <button onClick={handleSubmit} type="button" className="btn btn-primary mx-auto">
                  Cập nhật
                </button>
              )}
              &nbsp;
          <img src={ConvertToCloudFrontUrl(props.avatarManager)} className="AvatarManagerPix rounded-circle" alt={'Ảnh đại diện của người dùng '+props.username} />
          <hr />
        </div>
      </div>
  );
})
