import React from 'react'
import VideoCard from './videoCard/VideoCard'
import { useQuery } from '@apollo/client'
import {VIDEOS_RECOMMENDED} from '../../../graphql-client/Queries'
import NotificationError from '../../NotificationError'
import { Link } from 'react-router-dom';
import {useAuthContext} from '../../../context/AuthContext'
export default function HomeRight() {
    const {isAuthenticated} = useAuthContext()
    const {loading, error, data, fetchMore} = useQuery(VIDEOS_RECOMMENDED,
        { variables: { take: 20}, })
    if (loading) return (
        <div className="card" aria-hidden="true">
        <img src="..." className="card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title placeholder-glow">
            <span className="placeholder col-6" />
          </h5>
          <p className="card-text placeholder-glow">
            <span className="placeholder col-7" />
            <span className="placeholder col-4" />
            <span className="placeholder col-4" />
            <span className="placeholder col-6" />
            <span className="placeholder col-8" />
          </p>
        </div>
      </div>
    )
    if (error) return <p>Error! {error.message}</p>;
    
    const onLoadMore = () => fetchMore({
        variables: {
            take:data.videosRecommended.length+6
        },
    })

    const handleScroll = ({ currentTarget }, onLoadMore) => {
        if (
            currentTarget.scrollTop + currentTarget.clientHeight >=
            currentTarget.scrollHeight
        ) {
          onLoadMore();
        }
      };
    return (
        <div className="col-xxl-10 col-xl-10 col-lg-10 col-mb-10 col-sm-12 videosRecommended " 
            onScroll={e => handleScroll(e, onLoadMore)} 
        >
            <div className="p-0 marginVideoRecommend" >
                <h5 className='ms-3 mt-1'>HỌC LIỆU PHỔ BIẾN</h5>
                {
                data.videosRecommended.length===0
                ?<NotificationError text={'Chưa có video nào.'}></NotificationError>
                :<div className="row ms-auto">
                   {!isAuthenticated &&
                    <div className="alert alert-success" role="alert">
                        <h4 className='ms-4'><b>Thông tin tài khoản</b></h4>
                        <p className='ms-4'>
                            Hãy <Link to="/login" > đăng nhập hoặc đăng ký </Link>
                            để có thể sử dụng đầy đủ dịch vụ của Linklearn.vn
                        </p>
                    </div>}
                    {           
                        data.videosRecommended.map((video) =>
                            {  
                                return (                                  
                                    <VideoCard
                                        key={video.id}
                                        title ={video.title}
                                        username={video.user.username}
                                        date={video.createdAt}
                                        view={video.countView}
                                        like={video.countLike}  
                                        videoId={video.id}
                                        avatarUser={video.user.avatar}
                                        idUser={video.user.id}
                                        thumbnail={video.thumbnail}
                                        tags={video.tags}
                                        isAuthenticated ={isAuthenticated}
                                    >
                                    </VideoCard>
                                )
                            }
                        )
                    }
                </div>
                }
                    {
                    loading &&
                    <div className="card" aria-hidden="true">
                        <img src="..." className="card-img-top" alt="..." />
                        <div className="card-body">
                        <h5 className="card-title placeholder-glow">
                            <span className="placeholder col-6" />
                        </h5>
                        <p className="card-text placeholder-glow">
                            <span className="placeholder col-7" />
                            <span className="placeholder col-4" />
                            <span className="placeholder col-4" />
                            <span className="placeholder col-6" />
                            <span className="placeholder col-8" />
                        </p>
                        </div>
                    </div>
                    }
            </div>
        </div>
    )
}
