import React, { useState } from 'react';
import { UploadFileToS3 } from './UploadFileToS3';
import { useAuthContext } from '../context/AuthContext';
import { useQuery, useMutation } from '@apollo/client';
import { GET_AVATAR_USER_MANAGE } from '../graphql-client/Queries';
import { PUBLISH_USER } from '../graphql-client/Mutation';
export default function ComponentUploadFileToS3({ formState, setFormState, isUpdate }) {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [process, setProcess] = useState(0);
    const [fileSizeMB, setFileSizeMB] = useState(0);
    const { userId } = useAuthContext();
    const {data} = useQuery(GET_AVATAR_USER_MANAGE)
    const [updateMonthlyUploadSize] = useMutation(PUBLISH_USER);
        
    const handleUploadFile = async () => {
        if (!file) {
            alert('Vui lòng chọn một file trước khi tải lên.');
            return;
        }
        // Tính kích thước file trước khi upload
        const fileSize = file.size / (1024 * 1024);
        console.log('dung lượng file: '+fileSize);
        
        setFileSizeMB(fileSize);
         if (data?.userManage?.monthlyUploadSize > 0 && fileSize < data.userManage.monthlyUploadSize && fileSize < 250) {
                    // await UploadVideoToS3(file, setFile, formState, setFormState, setIsLoading, setProcess, userId);
                    await UploadFileToS3(file, formState, setFormState, setIsLoading, setProcess, userId);
                    await handleUpdateMonthlyUploadSize(fileSize);
                } else {
                    alert('Dung lượng tải lên trong tháng không đủ hoặc file nặng hơn 250MB.');
                }
        
    };
    const handleUpdateMonthlyUploadSize = async (fileSize) => {
        try {console.log('đã chạy handleUpdateMonthlyUploadSize');
        
            await updateMonthlyUploadSize({ variables: { monthlyUploadSize: Math.round(fileSize) } });
        } catch (error) {
            console.error("Lỗi khi cập nhật dung lượng upload:", error);
        }
    };
    // const handleUpdateMonthlyUploadSize = (fileSize) => {
    //     updateMonthlyUploadSize({variables: {monthlyUploadSize: Math.round(fileSize)}})
    //     // console.log(fileSize);
    //  }
    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
    };
    
    return (
        <div className='p-2'>
            {isLoading ? (
                <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: process + '%' }}
                        aria-valuenow={process} aria-valuemin={0} aria-valuemax={100}>
                        {process}%
                    </div>
                </div>
            ) : 
            // (
            //     !formState.urlDocument ? (
            //         <div className="d-flex justify-content-between">
            //             <input 
            //                 type="file" 
            //                 title='Tải lên tài liệu' 
            //                 accept=".pdf" // Chỉ cho phép các định dạng PDF, Word, và text
            //                 onChange={handleFileSelect} 
            //             />
            //             {file && (
            //                 <div className='pe-3'>
            //                     <button onClick={handleUploadFile} className=''>Tải lên</button>
            //                 </div>
            //             )}
            //         </div>
            //     ) : (
            //         formState.urlDocument && (
            //             <div className="alert alert-success d-flex align-items-center" role="alert">
            //                {isUpdate
            //                ?<p>Đã tải lên tài liệu, nhấn <strong>Cập nhật</strong> để xác nhận cập nhật tài liệu</p>
            //                :<div>
            //                     Đã tải lên tài liệu, hãy tiếp tục hoàn thiện chi tiết video
            //                 </div>} 
            //             </div>
            //         )
            //     )
            // )
            (
                formState.urlDocument ? (
                    <div className="alert alert-success d-flex align-items-center" role="alert">
                        {isUpdate ? (
                            <p>Đã tải lên tài liệu, nhấn <strong>Cập nhật</strong> để xác nhận cập nhật tài liệu</p>
                        ) : (
                            <p>Đã tải lên tài liệu, hãy tiếp tục hoàn thiện chi tiết video</p>
                        )}
                    </div>
                ) : (
                    <div className="d-flex justify-content-between">
                        <input 
                            type="file" 
                            title='Tải lên tài liệu' 
                            accept=".pdf" 
                            onChange={handleFileSelect} 
                        />
                        {file && (
                            <div className='pe-3'>
                                <button onClick={handleUploadFile} className='btn btn-primary'>Tải lên</button>
                            </div>
                        )}
                    </div>
                )
            )
            }
        </div>
    );
}
