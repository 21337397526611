import React from 'react';
// import './FeedBack.css'; // Import file CSS tùy chỉnh nếu cần
import FAQAccordion from './FAQAccordion';
export default function FeedBack() {
  return (
    <div className='container mt-5'>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-header ">
              <h4><b>1. Về chúng tôi</b></h4>
            </div>
            <div className="card-body">
              <p>
                <strong>LinkLearn.vn</strong> là nền tảng lưu trữ học liệu, siêu học liệu dưới dạng video bài giảng kết hợp tài liệu pdf,
                kết nối giáo viên, sinh viên, chuyên gia và doanh nghiệp để cùng nhau chia sẻ, học hỏi và phát triển.
                <br />
                <strong>LinkLearn.vn</strong> được xây dựng bởi đội ngũ phát triển luôn đau đáu về thế hệ trẻ và nền giáo dục nước nhà 
                với khát khao về tri thức và ước nguyện kết nối và lan tỏa tri thức đến mọi miền tổ quốc,
                giúp mọi cá nhân, hoàn cảnh, lứa tuổi có thể vượt qua rào cản địa lý, thời gian, chi phí để tiếp cận kiến thức chất lượng
                một cách nhanh nhất, hiệu quả nhất.
                Giúp giáo viên có một môi trường bình đẳng và chuyên nghiệp để có cơ hội chia sẻ kiến thức đến với mọi người không phân biệt khoảng cách
                và thời gian.
                <br />
              
        
              </p>  
            </div>
          </div>

          <div className="card mb-4">
            <div className="card-header ">
              <h4><b>2. Nhận thức xã hội</b></h4>
            </div>
            <div className="card-body">
              <p>
                Kiến thức là gốc rễ của sự phát triển, là chìa khóa mở ra cánh cửa thành công cho mỗi cá nhân, gia đình và xã hội. 
                Rào cản cản trở việc tiếp cận kiến thức chính là rào cản lớn nhất với sự phát triển của mỗi cá nhân và toàn bộ xã hội,
                công nghệ phát triển là lúc phá bỏ các rào cản cố hữu trước đó như khoảng cách địa lý, thời gian, chi phí.

                <br />
                Sự phát triển của công nghệ đã làm thay đổi cách chúng ta tiếp cận tri thức. Học tập trực tuyến đã trở thành  
                một nhu cầu thiết thực, LinkLearn mong muốn góp phần xây dựng một nền tảng giáo dục giúp học sinh, 
                sinh viên có cơ hội học tập 
                trong môi trường chuyên nghiệp, chất lượng và bình đẳng hơn.
                <br />
                Tầm nhìn trở thành nền tảng giáo dục trực tuyến hàng đầu tại Việt Nam, 
                góp phần thu hẹp khoảng cách tri thức giữa các vùng miền, 
                nâng cao chất lượng giáo dục và tạo cơ hội học tập cho mọi người, 
                không phân biệt điều kiện hay hoàn cảnh.
              </p>
            </div>
          </div>

          <div className="card mb-4">
            <div className="card-header ">
              <h4><b>3. Tầm nhìn và sứ mệnh</b></h4>
            </div>
            <div className="card-body">
              {/* <p> */}
                <p><strong>Mang tri thức đến mọi nơi:</strong> Đảm bảo học sinh, sinh viên ở mọi vùng miền đều có quyền tiếp cận nguồn kiến thức chất lượng.</p>
               
                <p><strong>Xây dựng cộng đồng học tập bền vững:</strong> Kết nối giáo viên, sinh viên, chuyên gia và doanh nghiệp để cùng nhau chia sẻ, học hỏi và phát triển.</p>
                
                <p><strong>Hỗ trợ giáo dục toàn diện:</strong> Cung cấp tài liệu, khóa học và công cụ hỗ trợ phù hợp với nhu cầu học tập đa dạng của người dùng.</p>
               
                <strong>Khuyến khích sự hợp tác và sáng tạo:</strong> Tạo môi trường để mọi người đóng góp, chia sẻ và phát triển tri thức một cách sáng tạo và hiệu quả.
              {/* </p> */}
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-header ">
              <h4><b>4. Câu hỏi thường gặp</b></h4>
            </div>
            <div className="card-body">
              <FAQAccordion />
            </div>
          </div>
         {/* Thẻ thông tin liên lạc */}
          <div className="card mb-4">
            <div className="card-header">
              <h4><b>5. Thông tin liên hệ</b></h4>
            </div>
            <div className="card-body">
              <p><strong>Công ty:</strong> TNHH dịch vụ công nghệ NHK</p>
              <p><strong>Hotline-Zalo:</strong> 0837914479</p>
              <p><strong>Email:</strong> linklearn.hotro@gmail.com</p>
              <p><strong>Website:</strong> <a href="https://linklearn.vn" target="_blank" rel="noopener noreferrer">https://linklearn.vn</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}