import React, {useState} from 'react'
import VideoCard from '../home/home_right/videoCard/VideoCard'
import { VIDEOS_LIKE } from '../../graphql-client/Queries'
import { useQuery } from '@apollo/client'
import NotificationError from '../NotificationError'
export default function VideosLikeRight() {
    const upTakeOnFetchMore = 12;
    const beginTake = 12;
    const [buttonStatus, setButtonStatus] = useState({
      isCountVideoDoClick: beginTake,
      showButtonNewVideo: false,
     })
    const {loading, error, data, fetchMore} = useQuery(VIDEOS_LIKE,{
        variables: {
            skip:0,
            take: beginTake
        }
    })
    if(loading) return <p>loading...</p>
    if(error) return <p>Error! {error.message}</p>;
    const onLoadMore = () => {
        fetchMore({
        variables: {
            take: data.videosLike.length+upTakeOnFetchMore
        },
      })}
    
    return (
        <div className="col-xxl-10 col-xl-10 col-lg-10 col-mb-10 col-sm-12 ">
            <div className="p-0 marginVideoRecommend" >
                <h5 className='ms-3 mt-1'>Video đã thích</h5>
                {
                    data.videosLike.length===0
                    ?<NotificationError text={'Bạn chưa thích Video nào.'}></NotificationError>
                    :<div className="row ms-auto">
                        {
                            data.videosLike.map((videoLike)=>
                                {
                                    return (                                  
                                        <VideoCard 
                                            key={videoLike.id}
                                            title ={videoLike.video.title}
                                            thumbnail={videoLike.video.thumbnail}
                                            date={videoLike.video.createdAt}
                                            view={videoLike.video.countView}
                                            like={videoLike.video.countLike}
                                            videoId={videoLike.video.id}
                                            avatarUser={videoLike.video.user.avatar}
                                            idUser={videoLike.video.user.id}
                                            username={videoLike.video.user.username}
                                            >
                                        </VideoCard>
                                    )
                                }
                            )
                        }
                        </div>
                }
                 {
                    data.videosLike.length===buttonStatus.isCountVideoDoClick 
                    && 
                    <button className=' btn  ms-2' 
                    onClick={()=>{
                        setButtonStatus({isCountVideoDoClick: buttonStatus.isCountVideoDoClick+upTakeOnFetchMore}) 
                        onLoadMore()
                    }}
                    > 
                        <b>
                            Xem thêm video
                        </b>
                    </button>
                }
            </div>
        </div>
    )
}
