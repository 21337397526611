import React,{useState} from 'react'
import FormNewListVideo from './formToolListVideo/FormNewListVideo'
import FormNewVideo from './formToolListVideo/FormNewVideo'
import VideoCard from '../../../home/home_right/videoCard/VideoCard'
import ShowVideoInListVideo from './ShowVideoInListVideo'
import {LIST_VIDEO_USER_MANAGE} from '../../../../graphql-client/Queries'
import { useQuery } from '@apollo/client'
import FormUpdateVideo from './formToolListVideo/formTitleVideo/FormUpdateVideo'
import FormDeleteVideo from './formToolListVideo/formTitleVideo/FormDeleteVideo'
import NotificationError from '../../../NotificationError'
import { ConvertToCloudFrontUrl } from '../../../../utils/ConvertToCloudFrontUrl'
function ManageListVideo() {
    const [statusForm, setStatusForm] = useState({
        showVideoInList: false,
        idListVideoShow:null,
        FormNewListVideo: false,
        FormNewVideo: '',
        FormUpdateListVideo:'',
        FormDeleteListVideo: '',
    })
    const [formStates, setFormStates] = useState({
        videoIdUpdate:null, 
        videoIdDelete:null,
        title:null, 
        document:null,
        description:null,
        publishVideoManager:false,
        videoListId:null
    });
    const [formStatesListVideo, setFormStatesListVideo] = useState({
        videoListIdUpdate:null,
        title:null, about:null,
        publishListVideoManager:false,
        idListVideoIsNewVideo:null,
        idListVideoDelete: null
        
    });
    const  [formShowVideo, setFormShowVideo] = useState({
        idVideoListShow: null
    })
    const upTakeOnFetchMore = 4;
    const beginTake = 4;
    const [buttonStatus, setButtonStatus] = useState({
        isCountListVideoDoClick: beginTake,// bằng với số take của data ban đầu
        showButtonNewVideo: false,
        
    })
    
      const handleShowFormUpdateListVideo = ( videoListId,  title, about,  publishListVideo )=>{
        setFormStatesListVideo({
            videoListIdUpdate:videoListId, 
            title:title,
            about: about,
            publishListVideoManager:publishListVideo,
            
        })
      }
      const handleShowFormUpdateVideo =  ( publishVideo, videoId, title, document,  description)=>{
        setFormStates({
            publishVideoManager:publishVideo,
            videoIdUpdate: videoId,
            title: title,
            document: document,
            description: description,
            
        })
      }
      const handleShowFormShowVideoInVideoList =  (idVideoList)=>{
        setFormShowVideo({
            idVideoListShow: idVideoList
        })
      }
      const handleShowFormDeleteVideo =  ( videoId, videoListId)=>{
        setFormStates({
            videoIdDelete: videoId,
            videoListId: videoListId
        })
      }

    const {loading, error, data, fetchMore} = useQuery(LIST_VIDEO_USER_MANAGE,{
        variables:{take: beginTake}
    })
    if(loading) return <p>loading...</p>
    if(error) return <p>Error! {error.message}</p>;

    const listVideoManager = data.listVideoUserManage;
    const onLoadMore =()=> {
        
        fetchMore({
        variables:{take: data.listVideoUserManage.length+upTakeOnFetchMore}
        })
    }
    return (
        <div >
            <hr />
            <div className='p-1'>
                <div className="alert alert-success" role="alert">
                    <h4 className='ms-4'><b>Thông tin tài khoản</b></h4>
                    <p className='ms-4'>
                        Để không bị giới hạn các tính năng của nhà phát triển Giáo Dục và nhận được hỗ trợ tốt hơn hãy hoàn thiện Tài Khoản của Bạn và liên hệ với chúng tôi qua email: 
                        linklearn.hotro@gmail.com hoặc zalo: 0837914479.
                    </p>
                </div>

                <button 
                        onClick={()=>setStatusForm({FormNewListVideo:true})}
                        className="btn btn-secondary NewVideoListButton ms-2" type="button" 
                        data-bs-toggle="modal" data-bs-target="#NewVideoListModal" data-bs-whatever
                >
                        <i className="fas fa-plus"></i>
                        <b> Tạo khóa học mới</b>
                </button>
                
                <div className="modal fade" id="NewVideoListModal" tabIndex={-1} aria-labelledby="NewVideoListModalModalLabel" aria-hidden="true">
                    <div className="modal-dialog fix-maxWidth">
                        {
                            statusForm.FormNewListVideo &&
                            <FormNewListVideo></FormNewListVideo>
                        }
                    </div>
                </div>
            
            </div>
            
            <div  
                className="modal fade " id={
                    formStates.idListVideoIsNewVideo
                    ?"newVideoModal"+formStates.idListVideoIsNewVideo
                    :"newVideoModal-default"
                } 
                tabIndex={-1} 
                aria-labelledby="newVideoModalModalLabel" 
                aria-hidden="true"
                data-bs-backdrop="static" 
                data-bs-keyboard="false"
            >
                <div className="modal-dialog fix-maxWidth">
                  
                    <FormNewVideo
                        videoListId={formStates.idListVideoIsNewVideo}
                    >
                    </FormNewVideo>
                    
                </div>
            </div>
            <div className="modal fade" id={
                        formStatesListVideo.idListVideoDelete
                        ?"deleteListVideoManager"+ formStatesListVideo.idListVideoDelete
                        : "deleteListVideoManager-default"
                    } 
                    tabIndex={-1} 
                    aria-labelledby="deleteListVideoModalModalLabel" aria-hidden="true">
                    <div className="modal-dialog fix-maxWidth">
                    {
                    formStatesListVideo.idListVideoDelete &&
                    <FormDeleteVideo 
                        publishVideoManager = {true} 
                        videoListId = {formStatesListVideo.idListVideoDelete}
                    >
                    </FormDeleteVideo>}
                    </div>
            </div>
            <div className={formShowVideo.idVideoListShow?"modal fade setIndexModal": "modal fade"} 
                    id={
                        formStates.videoIdDelete
                        ?"deleteVideoManager"+ formStates.videoIdDelete
                        : "deleteVideoManager-default"
                    } 
                    tabIndex={-1} 
                    aria-labelledby="deleteVideoModalModalLabel" aria-hidden="true">
                    <div className="modal-dialog fix-maxWidth">
                   {formStates.videoIdDelete &&
                    <FormDeleteVideo 
                        deleteVideo = {true} 
                        videoId = {formStates.videoIdDelete}
                        videoListId= {formStates.videoListId}
                    >
                    </FormDeleteVideo>}
                    </div>
            </div>
                <div 
                    className="modal fade" 
                    id={formStatesListVideo.videoListIdUpdate 
                        ? "publishListVideoManager" + formStatesListVideo.videoListIdUpdate 
                        : "publishListVideoManager-default"
                        }  
                    tabIndex={-1} 
                    aria-labelledby={"publishListVideoManagerLabel"+formStatesListVideo.videoListIdUpdate} 
                    aria-hidden="true"
                 >
                   <div className="modal-dialog fix-maxWidth">
                   {  formStatesListVideo.videoListIdUpdate 
                       && <FormUpdateVideo 
                            publishListVideoManager = {formStatesListVideo.publishListVideoManager} 
                            videoListId = {formStatesListVideo.videoListIdUpdate} 
                            title = {formStatesListVideo.title}
                            about = {formStatesListVideo.about} 
                        ></FormUpdateVideo>
                    }
                    </div>
                </div>
                <div 
                    className={formShowVideo.idVideoListShow?"modal fade setIndexModal": "modal fade"}
                    id={formStates.videoIdUpdate 
                        ? "publishVideoManager" + formStates.videoIdUpdate 
                        : "publishVideoManager-default"
                        }  
                    tabIndex={-1} 
                    aria-labelledby={"publishVideoManagerLabel"+formStates.videoIdUpdate} 
                    aria-hidden="true"
                 >
                    <div className="modal-dialog fix-maxWidth">
                       { 
                            formStates.videoIdUpdate &&
                            <FormUpdateVideo
                            publishListVideoManager={false}
                            publishVideoManager={formStates.publishVideoManager}
                            videoId = {formStates.videoIdUpdate} 
                            title = {formStates.title}
                            description={formStates.description}
                            document={formStates.document}
                            ></FormUpdateVideo>
                        }
                    </div>
                </div>
                <div 
                    // key={formShowVideo.idVideoListShow}
                    className={"modal fade"}
                    id={formShowVideo.idVideoListShow 
                        ? "showVideoInVideoListManager" + formShowVideo.idVideoListShow
                        : "showVideoInVideoListManager-default"
                        }  
                    tabIndex={-1} 
                    aria-labelledby={"showVideoInVideoListManagerLabel"+formShowVideo.idVideoListShow} 
                    aria-hidden="true"
                 >
                    <div className="modal-dialog fix-maxWidth">
                        {
                            formShowVideo.idVideoListShow &&
                            <ShowVideoInListVideo 
                                key={formShowVideo.idVideoListShow}
                                idVideoList={formShowVideo.idVideoListShow}
                                handleShowFormUpdateVideo={handleShowFormUpdateVideo}
                                handleShowFormDeleteVideo={handleShowFormDeleteVideo}
                                videoIdInFormState = {formStates.videoIdUpdate}
                                videoIdInFormDelete = {formStates.videoIdDelete}
                                setFormShowVideo={setFormShowVideo}
                            >
                            </ShowVideoInListVideo>
                        }
                    </div>
                </div>
            <hr />
            {listVideoManager.length === 0
            ?<div className="alert alert-dark" role="alert">
                Chưa có khóa học nào hãy thêm khóa học của bạn.
            </div> 
            :listVideoManager.map((videoList)=>{
                const videoListThumbnail=ConvertToCloudFrontUrl(videoList.thumbnail)
                return (
                    <div  key={videoList.id}>
                            <div className="card m-3 p-1 ">
                                <div className="row g-0">
                                    <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12">
                                    <img className="imgListVideo"  alt={"Ảnh đại diện của khóa học "+videoList.title} 
                                        src={
                                            videoList.video.length===0 && videoList.thumbnail ===''
                                            ?'default-image.jpg'
                                            :(
                                             videoList.thumbnail ===''
                                             ?videoList.video[0].thumbnail
                                             :videoListThumbnail
                                             )
                                        }
                                    />
                                       { videoList.video
                                       ?''
                                       :(
                                        videoList.thumbnail ===''
                                        ?<img className="imgListVideo ms-2 mt-2" src={ConvertToCloudFrontUrl(videoList.video[0].thumbnail)} alt={"Ảnh đại diện của khóa học "+videoList.title}/>
                                        :<img className="imgListVideo ms-2 mt-2" src={ConvertToCloudFrontUrl(videoList.thumbnail)} alt={"Ảnh đại diện của khóa học "+videoList.title} />
                                        )
                                        }
                                    </div>
                                    <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12">
                                        <div className="card-body">
                                            <div className='d-flex justify-content-between'>
                                                <h5 className="card-title text_per_line">
                                                    {videoList.title} 
                                                </h5>
                                                <div className="btn-group dropup">
                                                    <button type="button" title='Chỉnh sửa khóa học' className="btn rounded" data-bs-toggle="dropdown" aria-expanded="true">
                                                        <i className="fa fa-wrench" aria-hidden="true"></i>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <button
                                                            onClick={()=>{setFormStatesListVideo({idListVideoDelete:videoList.id})}}
                                                            className="btn btn-light w-100" data-bs-toggle="modal" type="button" 
                                                            data-bs-target={ 
                                                                formStatesListVideo.idListVideoDelete 
                                                                ? "#deleteListVideoManager"+ formStatesListVideo.idListVideoDelete 
                                                                : "#deleteListVideoManager-default"
                                                            }
                                                        >
                                                            <i className="fa fa-trash" aria-hidden="true" data-bs-dismiss="modal" ></i> Xóa
                                                        </button>
                                                        <button 
                                                            onClick={()=>handleShowFormUpdateListVideo(videoList.id,
                                                                videoList.title, videoList.about, true,
                                                                )}
                                                            className="btn btn-light w-100 mt-1"
                                                            data-bs-target={
                                                                formStatesListVideo.videoListIdUpdate 
                                                                ? "#publishListVideoManager" +formStatesListVideo.videoListIdUpdate 
                                                                : "#publishListVideoManager-default"
                                                            } 
                                                            data-bs-toggle="modal" 
                                                        >
                                                                <i className="fas fa-pen" /> Cập nhật
                                                        </button>
                                                    </ul> 
                                                </div>
                                                
                                            </div>
                                            
                                            <p className="card-text text_per_line">
                                                {videoList.about}
                                            </p>
                                            <br />
                                            <div className="statisticalVideo d-flex flex-column">
                                                <div className="d-flex ">
                                                    <p>
                                                        {videoList.countVideo}
                                                    </p>
                                                    &nbsp; 
                                                    <p>Video</p>
                                                </div>
                                                <div className='d-flex '>
                                                    <button 
                                                        onClick={()=>setFormStates({idListVideoIsNewVideo: videoList.id})}
                                                        className="btn btn-secondary" type="button" data-bs-toggle="modal" 
                                                        data-bs-target={
                                                            formStates.idListVideoIsNewVideo
                                                            ?"#newVideoModal"+formStates.idListVideoIsNewVideo
                                                            :"#newVideoModal-default"
                                                        } 
                                                    >
                                                        <i className="fas fa-plus text-white me-1" > </i>
                                                        <b className='text-white textButtonAdd'> 
                                                            Tạo học liệu mới
                                                        </b>
                                                    </button>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {videoList.countVideo>0 &&
                                    <div className='m-3 '>
                                        <button  
                                            onClick={()=>{
                                                setStatusForm({idListVideoShow: videoList.id})
                                                }
                                            }
                                            type="button"
                                            className="btn " 
                                            data-bs-toggle="collapse" 
                                            data-bs-target={"#listVideo" + videoList.id } 
                                            aria-expanded="false"
                                        >
                                            <b>Danh sách học liệu</b>
                                        </button>
                                    </div>
                                }
                            </div>
                        <div className="collapse multi-collapse" id={"listVideo" + videoList.id} >
                            <div className="row backgroundShow m-3">
                                {   
                                videoList.video.length===0
                                ?<NotificationError text={'Chưa có video nào.'}></NotificationError>
                                :
                                videoList.video.map(
                                    (video)=>{
                                        return (
                                        <VideoCard
                                            key={video.id}
                                            publishVideoManager={true}
                                            document={video.document}
                                            description={video.description}
                                            title ={video.title}
                                            date={video.createdAt}
                                            view={video.countView}
                                            like={video.countLike}
                                            videoId={video.id}
                                            tags={video.tags}
                                            thumbnail={video.thumbnail}
                                            videoIdInFormState = {formStates.videoIdUpdate}
                                            videoIdInFormDelete = {formStates.videoIdDelete}
                                            isShowFormUpdateVideo = {()=>handleShowFormUpdateVideo(true, video.id,  
                                            video.title, video.document, video.description)}
                                            isShowFormDeleteVideo=  { ()=> handleShowFormDeleteVideo(video.id, videoList.id)}
                                        >
                                        </VideoCard>
                                        )
                                    }) 
                                }
                            {/* {videoList.video.length >=6 &&
                            <button className=' btn ' 
                                onClick={()=>handleShowFormShowVideoInVideoList(videoList.id)}
                                 data-bs-toggle="modal" 
                                data-bs-target={
                                    formShowVideo.idVideoListShow 
                                    ? "#showVideoInVideoListManager" + formShowVideo.idVideoListShow
                                    : "#showVideoInVideoListManager-default"
                                } 
                            >
                                <b>
                                    Xem tất cả Video
                                </b> 
                            </button>} */}
                            </div>
                        </div>
                    </div>
                )
            })}
            {
              listVideoManager.length===buttonStatus.isCountListVideoDoClick && 
                <button className=' btn  ms-2' 
                onClick={()=>{
                    setButtonStatus({isCountListVideoDoClick: buttonStatus.isCountListVideoDoClick + upTakeOnFetchMore})// cộng :5 băng với số dữ liệu được lấy thêm 
                    onLoadMore()
                }}
                > 
                <b>
                    Xem thêm khóa học
                </b>
                </button>
            }
        </div>

    )
}
export default React.memo(ManageListVideo);