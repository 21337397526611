export const UploadFileToS3 = async (file, formState, setFormState, setIsLoading, setProcess, userId) => {
    setIsLoading(true);
    
    if (!file) return;

    try {
        // Gửi request để lấy presigned URL
        const response = await fetch(`${process.env.REACT_APP_URL_WEB_SERVER_ROUTER_UPLOADS3}/?fileName=${file.name}&fileType=${file.type}&userId=${userId}`, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Failed to get presigned URL');
        }

        const data = await response.json();

        // Kiểm tra nếu data có chứa `url`
        if (!data.url) {
            throw new Error('Response does not contain the URL');
        }
        
        
        const { url } = data;

        // Tải file lên S3 bằng presigned URL
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', url, true);
        xhr.setRequestHeader('Content-Type', file.type);

        xhr.upload.onprogress = function (event) {
            const progress = Math.round((event.loaded / event.total) * 100);
            setProcess(progress);
        };
        const urlFix =url.split('?')[0]
        
        xhr.onload = () => {
            if (xhr.status === 200) {
                
                setFormState({
                    ...formState,
                    urlDocument: urlFix, // Lấy URL của file sau khi tải lên
                    // urlDocument: url.split('?')[0], // Lấy URL của file sau khi tải lên
                });
                
            } else {
                console.error('Upload error', xhr.responseText);
                alert('Tải lên không thành công')
            }
            setIsLoading(false);
            setProcess(0)
            
        };

        xhr.onerror = () => {
            console.error('Error during file upload');
        };

        xhr.send(file);
    } catch (error) {
        console.error('Error uploading file to S3:', error);
    } finally {
        setIsLoading(false);
    }
};
