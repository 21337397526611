
export const UploadVideoToS3 = async (file, setFile, formState, setFormState, setIsLoading, setProcess, userId) => {
    setIsLoading(true);
    
    if (!file) return;
    
    // Gửi request để lấy presigned URL
    try {
        
        const response = await fetch(process.env.REACT_APP_URL_WEB_SERVER_ROUTER_UPLOADS3+`/?fileName=${encodeURIComponent(file.name)}&fileType=${encodeURIComponent(file.type)}&userId=${userId}`, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Failed to get presigned URL');
        }

        const { url } = await response.json();

        // Tải file lên S3 bằng presigned URL
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', url, true);
        xhr.setRequestHeader('Content-Type', file.type);

        xhr.upload.onprogress = function (event) {
            var progress = Math.round((event.loaded / event.total) * 100);
            setProcess(progress);
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                
                setFormState({
                    ...formState,
                    url: url.split('?')[0], // Lấy URL của file sau khi tải lên
                });
                console.log('Image uploaded to:', url.split('?')[0]);
            } else {
                console.error('Upload error', xhr.responseText);
            }
            setIsLoading(false); 
            setProcess(0)
            setFile(null);
            // setFileSizeMB(file.size / (1024 * 1024));
            // console.log('dung lượng video: '+fileSizeMB);
        };

        xhr.send(file);
        // setIsLoading(false);
    } catch (error) {
        console.error('Error uploading video to S3:', error);
    } 
};
// export const UploadVideoToS3 = async (file, formState, setFormState, setIsLoading, setProcess) => {
//     setIsLoading(true);

//     if (!file) return;

//     const chunkSize = 5 * 1024 * 1024; // 5MB per chunk
//     const totalChunks = Math.ceil(file.size / chunkSize);
    
//     let currentChunk = 0;

//     // Hàm tải từng phần lên S3
//     const uploadChunk = async (startByte, endByte) => {
//         try {
//             // Gửi request để lấy presigned URL
//             const response = await fetch(process.env.REACT_APP_URL_WEB_SERVER_ROUTER_UPLOADS3 + `/?fileName=${file.name}&fileType=${file.type}&startByte=${startByte}&endByte=${endByte}`, {
//                 method: 'GET',
//             });

//             if (!response.ok) {
//                 throw new Error('Failed to get presigned URL for chunk');
//             }

//             const { url } = await response.json();

//             // Lấy phần video cần tải lên
//             const chunk = file.slice(startByte, endByte);

//             // Tải phần video lên S3 qua presigned URL
//             const xhr = new XMLHttpRequest();
//             xhr.open('PUT', url, true);
//             xhr.setRequestHeader('Content-Type', file.type);

//             xhr.upload.onprogress = function (event) {
//                 var progress = Math.round(((currentChunk * chunkSize + event.loaded) / file.size) * 100);
//                 setProcess(progress); // Cập nhật tiến trình tải lên
//             };

//             xhr.onload = () => {
//                 if (xhr.status === 200) {
//                     currentChunk += 1; // Chuyển sang phần tiếp theo
//                     if (currentChunk < totalChunks) {
//                         uploadChunk(currentChunk * chunkSize, Math.min((currentChunk + 1) * chunkSize, file.size));
//                     } else {
//                         // Hoàn thành việc tải video lên
//                         setFormState({
//                             ...formState,
//                             url: url.split('?')[0], // Lấy URL của file đã tải lên
//                         });
//                         setIsLoading(false);
//                         setProcess(0);
//                     }
//                 } else {
//                     console.error('Upload error', xhr.responseText);
//                     setIsLoading(false);
//                     setProcess(0);
//                 }
//             };

//             xhr.send(chunk);
//         } catch (error) {
//             console.error('Error uploading chunk:', error);
//         }
//     };

//     // Bắt đầu tải phần đầu tiên
//     uploadChunk(0, chunkSize);
// };
