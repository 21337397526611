
import React, {useState} from 'react'
import { UploadVideoToS3 } from './UploadVideoToS3';
import { useQuery, useMutation } from '@apollo/client';
import { GET_AVATAR_USER_MANAGE } from '../graphql-client/Queries';
import { PUBLISH_USER } from '../graphql-client/Mutation';
export default function ComponentUploadVideoToS3({ formState, setFormState}) {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [process, setProcess] = useState(0);
    const [fileSizeMB, setFileSizeMB] = useState(0);
    const [updateMonthlyUploadSize] = useMutation(PUBLISH_USER);
    const {data} = useQuery(GET_AVATAR_USER_MANAGE)
    const userId = data && data.userManage && data.userManage.id
    
    
    const handleUploadVideo = async () => {
        if (!file) {
            alert('Vui lòng chọn một file trước khi tải lên.');
            return;
        }
        
        // Tính kích thước file trước khi upload
        const fileSize = file.size / (1024 * 1024);
        setFileSizeMB(fileSize);
    
        if (data?.userManage?.monthlyUploadSize > 0 && fileSize < data.userManage.monthlyUploadSize && fileSize < 2048) {
            await UploadVideoToS3(file, setFile, formState, setFormState, setIsLoading, setProcess, userId);
            await handleUpdateMonthlyUploadSize(fileSize);
        } else {
            alert('Dung lượng tải lên trong tháng không đủ hoặc video vượt quá 2GB.');
        }
    };

      const handleFileVideoSelect = (e) => {
        const selectedFile = e.target.files[0];
    
        if (!selectedFile) return;
    
        // Kiểm tra nếu file có phải video không
        if (!selectedFile.type.startsWith("video/")) {
            alert("Chỉ hỗ trợ upload video.");
            return;
        }
        setFile(selectedFile);
        // Lấy kích thước file (đổi từ byte sang MB)
        setFileSizeMB((selectedFile.size / (1024 * 1024)).toFixed(2));

        // setFileSizeMB(fileSizeMB);
        //Tạo URL tạm cho video
        const videoURL = URL.createObjectURL(selectedFile);
         // Đọc metadata để lấy thời lượng video
        const video = document.createElement("video");
        video.preload = "metadata";

        // Đợi metadata load xong
        video.onloadedmetadata = () => {
            const duration = video.duration; // Thời lượng video (giây)
            const fileSizeBits = selectedFile.size * 8; // Chuyển bytes sang bits
            // console.log(`File Name: ${selectedFile.name}`);
            // console.log(`Dung lượng: ${fileSizeMB} MB`);
            // console.log(`Thời lượng: ${duration.toFixed(2)} giây`);
            const bitrate = fileSizeBits / duration / 1000; // Bitrate tính bằng kbps

            console.log(`Bitrate: ${bitrate.toFixed(2)} kbps`);
            
            // Kiểm tra nếu bitrate vượt quá 6000 kbps
            if (bitrate > 6000) {
                alert(`Cảnh báo: Video của bạn có bitrate ${bitrate.toFixed(2)} kbps, vượt quá mức khuyến cáo (6000 kbps). Hãy giảm tốc độ bit của video xuống.`);
                // setFile(null)
                e.target.value = ""; 
                URL.revokeObjectURL(videoURL); // Giải phóng URL trước khi return
                return;
            }

            // Giải phóng URL sau khi lấy xong metadata
            URL.revokeObjectURL(videoURL);

            // Lưu file vào state
            setFile(selectedFile);
        };

        //Đặt src sau khi gán sự kiện để đảm bảo `onloadedmetadata` được kích hoạt
        video.src = videoURL;
    };
    const handleUpdateMonthlyUploadSize = async (fileSize) => {
        try {
            await updateMonthlyUploadSize({ variables: { monthlyUploadSize: Math.round(fileSize) } });
        } catch (error) {
            console.error("Lỗi khi cập nhật dung lượng upload:", error);
        }
    };

  return (
    <div className='p-2'>
        {
                !formState.url 
                ?<div className="d-flex justify-content-between">
                    <input type="file" title='tải lên video' accept="video/*" onChange={handleFileVideoSelect} />
                    {
                        file && !process && 
                        <div className='pe-3'>
                            <button onClick={handleUploadVideo} className=''>Tải lên</button>
                        </div>
                    }
                </div>
                :
                (
                    formState.url && 
                    <>
                    {/* {handleUpdateMonthlyUploadSize()} */}
                    <div className="alert d-flex flex-column align-items-center" role="alert" >
                        <video className='isVideoDownload' controls src={formState.url}></video>
                        <div>
                            Đã tải lên Video, hãy tiếp tục hoàn thiện chi tiết video
                        </div>
                    </div>
                    </>
                    
                )
        }
              { 
              isLoading && 
              <div className="progress mt-2">
                    <div className="progress-bar" role="progressbar" style={{width: process+'%'}} 
                    aria-valuenow={process} aria-valuemin={0} aria-valuemax={100}
                    >
                        {process}%
                    </div>
                </div>}
        
    </div>
  )
}
