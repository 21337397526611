// import { useMutation } from '@apollo/client'
// import React from 'react'
// import { PUBLISH_USER } from '../../../../graphql-client/Mutation'
// import { useState } from 'react'
// import AvatarForm from './settingForm/AvatarForm'
// import CoverForm from './settingForm/CoverForm'
// import {GET_AVATAR_USER_MANAGE} from '../../../../graphql-client/Queries'

// export default React.memo(function Setting(props) {
//   const [fromSettingUser, setFromSettingUser] = useState({
//     fromName:'',
//     fromAvatar:'', 
//     fromCover:'',
//     });
    
 
//   const [settingUser] = useMutation(PUBLISH_USER,
//     {
//       variables:{
//         username:fromSettingUser.fromName,
//       },
//       update:(cache, {data: usernameNew})=>{
//         cache.writeQuery({
//           query:GET_AVATAR_USER_MANAGE,
//           data:{
//             userManage: usernameNew.publishUser
//           }
//         });
//       },
//     }
//    );
//    console.log("setting manage 2");
   
//   return (
//           <div className="row ">
//             <div className="col-xl-10 col-lg-10 col-mb-12 col-sm-12 mx-auto" >
//               <div className="form-group m-1">
//                   <form action="submit"
//                     onSubmit={(e) => {
//                       e.preventDefault();
//                       settingUser();
//                       setFromSettingUser({fromName:''})
//                       alert('Đã cập nhật thành công')
//                     }}
//                   >
//                   <h3 className='d-flex justify-content-center'>
//                     Thông tin cá nhân
//                   </h3>
//                   <hr />
//                   <label htmlFor="inputName">
//                     <b className='m-1 p-1'>
//                       Tên kênh: {props.nameManager}
//                     </b>
//                   </label>

//                   <input 
//                       type="text" name="inputName" id="inputName" className="form-control m-1  p-2" 
//                       placeholder="Nhập vào tên mới của kênh" 
//                       aria-describedby="helpId" 
//                       value={fromSettingUser.fromName}
//                       onChange={(e) =>
//                         setFromSettingUser({
//                           ...fromSettingUser,
//                           fromName: e.target.value
//                         })}
//                    />
//                   <button type="submit" className="btn btn-primary mt-1 ms-1">
//                     Cập nhật
//                   </button>
//                 </form>
//                 <hr />
//                 <AvatarForm 
//                   avatarManager = {props.avatarManager}
//                 >
//                 </AvatarForm>
//                 <hr />
//                 <CoverForm 
//                   coverManager = {props.coverManager}
//                 >
//                 </CoverForm>
//               </div>
//             </div>
//           </div>
//     )
// })
import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { PUBLISH_USER, UPDATE_PASSWORD } from '../../../../graphql-client/Mutation'
import AvatarForm from './settingForm/AvatarForm'
import CoverForm from './settingForm/CoverForm'
import { GET_AVATAR_USER_MANAGE } from '../../../../graphql-client/Queries'

export default React.memo(function Setting(props) {
  const [fromSettingUser, setFromSettingUser] = useState({
    fromName: '',
    fromAvatar: '',
    fromCover: '',
  });

  const [passwordInfo, setPasswordInfo] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [settingUser] = useMutation(PUBLISH_USER, {
    variables: {
      username: fromSettingUser.fromName,
    },
    update: (cache, { data: usernameNew }) => {
      cache.writeQuery({
        query: GET_AVATAR_USER_MANAGE,
        data: {
          userManage: usernameNew.publishUser,
        },
      });
    },
  });

  const [changePassword] = useMutation(UPDATE_PASSWORD, {
    variables: {
      oldPassword: passwordInfo.oldPassword,
      newPassword: passwordInfo.newPassword,
    },
    onCompleted: () => {
      alert('Mật khẩu đã được cập nhật thành công');
      setPasswordInfo({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    },
    onError: (error) => {
      alert(`Có lỗi xảy ra: ${error.message}`);
    },
  });
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (passwordInfo.newPassword !== passwordInfo.confirmPassword) {
      alert('Mật khẩu mới và xác nhận mật khẩu không khớp.');
      return;
    }
    changePassword();
  };

  return (
    <div className="row">
      <div className="col-xl-10 col-lg-10 col-mb-12 col-sm-12 mx-auto">
        <div className="form-group">
        <h3 className="d-flex justify-content-center m-3">Thông tin cá nhân</h3>
          <div className="alert alert-success" role="alert">
            <h4 className='ms-4'><b>Thông tin tài khoản</b></h4>
            <p className='ms-4'>
                Để không bị giới hạn các tính năng của nhà phát triển Giáo Dục và nhận được hỗ trợ tốt hơn hãy hoàn thiện Tài Khoản của Bạn và liên hệ với chúng tôi qua email: 
                linklearn.hotro@gmail.com hoặc zalo: 0837914479.
            </p>
          </div>
          <h5>Tên kênh</h5>
          {/* Form cập nhật thông tin người dùng */}
          <form
            className='m-2'
            action="submit"
            onSubmit={(e) => {
              e.preventDefault();
              settingUser();
              setFromSettingUser({ fromName: '' });
              alert('Đã cập nhật thành công');
            }}
          >
            
            <input
              type="text"
              name="inputName"
              id="inputName"
              className="form-control"
              placeholder="Nhập vào tên mới của kênh"
              aria-describedby="helpId"
              value={fromSettingUser.fromName}
              onChange={(e) =>
                setFromSettingUser({
                  ...fromSettingUser,
                  fromName: e.target.value,
                })
              }
            />
            <button type="submit" className="btn btn-primary mt-2 ">
              Cập nhật
            </button>
            <hr />
          </form>
          
          <h5>Thay đổi mật khẩu</h5>
          {/* Form thay đổi mật khẩu */}
          <form className='m-2'onSubmit={handlePasswordSubmit}>
            <div className="form-group mt-1">
              <label htmlFor="oldPassword ">Mật khẩu cũ</label>
              <input
                type="password"
                className="form-control"
                id="oldPassword"
                placeholder="Nhập mật khẩu cũ"
                value={passwordInfo.oldPassword}
                onChange={(e) =>
                  setPasswordInfo({ ...passwordInfo, oldPassword: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group mt-1">
              <label htmlFor="newPassword ">Mật khẩu mới</label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                placeholder="Nhập mật khẩu mới"
                value={passwordInfo.newPassword}
                onChange={(e) =>
                  setPasswordInfo({ ...passwordInfo, newPassword: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group mt-1">
              <label htmlFor="confirmPassword ">Xác nhận mật khẩu mới</label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                placeholder="Xác nhận mật khẩu mới"
                value={passwordInfo.confirmPassword}
                onChange={(e) =>
                  setPasswordInfo({ ...passwordInfo, confirmPassword: e.target.value })
                }
                required
              />
            </div>
            <button type="submit" className="btn btn-primary mt-2">
              Cập nhật 
            </button>
            <hr />
          </form>
          

          {/* AvatarForm và CoverForm */}
          <AvatarForm avatarManager={props.avatarManager} userId={props.id}/>
          <CoverForm coverManager={props.coverManager} userId={props.id}/>
        </div>
      </div>
    </div>
  );
});
