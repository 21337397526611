import React, { Component } from 'react'

export default class TermsOfService extends Component {
  render() {
    return (
        <div className='container mt-5'>
            <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                <div className="card-header  ">
                    <h1 className=''><b>Điều khoản sử dụng</b></h1>
                </div>
                <div className="card-body">
                <section className="mt-4">
        
        <p>Chào mừng bạn đến với LinkLearn.vn. 
            Bằng việc đăng ký mở tài khoản, đăng nhập hoặc sử dụng Sản phẩm, 
            bạn đồng ý với chúng tôi về các điều khoản cũng như các thay đổi được đăng 
            tải chính thức, công khai trên hệ thống của chúng tôi.
            Khi sử dụng trang web và các dịch vụ của chúng tôi, 
            bạn đồng ý tuân thủ các điều khoản sử dụng dưới đây. 
            Nếu bạn không đồng ý với bất kỳ điều khoản nào, vui lòng ngừng sử dụng dịch vụ.</p>
      </section>

      <section className="mt-4">
        Các thuật ngữ được sử dụng trong thỏa thuận này:
        <ul className="list-disc pl-6">
          <li><strong>"Người dùng/Bạn"</strong>: Cá nhân hoặc tổ chức truy cập và sử dụng dịch vụ của LinkLearn.vn.</li>
          <li><strong>"Nhà cung cấp/Chúng Tôi"</strong>: Công ty TNHH dịch vụ công nghệ NHK, nhà cung cấp của chúng tôi</li>
          <li><strong>"Dịch vụ"</strong>: Bao gồm nhưng không giới hạn ở việc truy cập khóa học, tải lên nội dung, tham gia thảo luận và các chức năng khác trên nền tảng.</li>
          <li><strong>"Nội dung/sản phẩm"</strong>: Bài giảng, video, tài liệu, bình luận và mọi thông tin được đăng tải trên linklearn.vn.</li>
        </ul>
      </section>

      <section className="mt-4">
        <h2 className="text-xl font-semibold">1. Nguyên tắc sử dụng sản phẩm</h2>
        <ul className="list-disc pl-6">
          <li>
          Công ty TNHH dịch vụ công nghệ NHK là chủ sở hữu đối với các quyền sở hữu trí 
          tuệ liên quan tới Sản 
          phẩm Chúng Tôi. Thông qua Điều khoản này, chúng tôi cho phép bạn 
          sửu dụng và hưởng các lợi ích từ việc sử dụng Sản phẩm của chúng tôi theo 
          cách thức được quy định trong Điều khoản này. Bạn sử dụng Sản phẩm của chúng 
          tôi không đồng nghĩa bạn là chủ sở hữu của bất kỳ quyền sở hữu trí tuệ nào đối 
          với Sản phẩm của chúng tôi hay nội dung mà bạn truy cập. Bạn không được 
          sử dụng nội dung từ Sản phẩm của chúng tôi trừ khi bạn được chủ sở hữu của
           nội dung đó cho phép hoặc được luật pháp cho phép.
          </li>
          <li>
          Bạn không được phép sử dụng bất cứ nhãn hiệu thương mại nào của chúng tôi hay
           bất kỳ nhãn hiệu tương
           tự nào có thể gây nhầm lẫn trừ khi có văn bản cho phép trước của chúng tôi. 
           Bạn không được sao chép, sửa đổi, phân phối, bán hoặc cho thuê bất kỳ phần
            nào trong Sản phẩm của chúng tôi hoặc các phần mềm có trong Sản phẩm đó. 
            Bạn cũng không được phép tạo các sản phẩm phái sinh, thiết kế kỹ thuật ngược 
            hoặc tìm cách trích xuất mã nguồn của phần mềm đó, ngoại trừ trường hợp bạn
             đã được sự cho phép của chúng tôi bằng văn bản.
          </li>
          <li>
          Nếu bạn thu thập thông tin từ người dùng, bạn sẽ xin sự chấp thuận của họ, 
          trong đó phải nêu rõ ràng bạn là người thu thập thông 
          tin và đăng chính sách bảo mật thông tin giải thích thông tin bạn thu thập
           và cách bạn sẽ sử dụng thông tin đó.
          </li>
          <li>Tuân thủ các quy định về bảo vệ dữ liệu cá nhân theo chính sách bảo mật của LinkLearn.vn.</li>
        </ul>
      </section>
      <section className="mt-4">
        <h2 className="text-xl font-semibold">2. Quyền và Trách Nhiệm Của Người Dùng</h2>
        <ul className="list-disc pl-6">
          <li>Cung cấp thông tin chính xác khi đăng ký tài khoản.</li>
          <li>Bạn cam kết với Chúng Tôi rằng bạn là đối tượng chịu trách nhiệm pháp lý,
            trách nhiệm giải trình và làm việc với chúng tôi liên quan đến tính hợp pháp của 
            những nội dung mà bạn đã thực hiện trong quá trình sử dụng Sản phẩm của chúng tôi..
            </li>
          <li>Không sử dụng nội dung vi phạm bản quyền, phát tán thông tin sai sự thật hoặc vi phạm pháp luật.</li>
          <li>Bạn cam kết không sử dụng Sản phẩm của chúng tôi vào mục đích vi phạm các pháp luật hiện hành</li>
          <li>Không thực hiện các hành vi phá hoại hệ thống, gian lận hoặc xâm phạm quyền lợi của người dùng khác.</li>
          <li>Tuân thủ các quy định về bảo vệ dữ liệu cá nhân theo chính sách bảo mật của LinkLearn.vn.</li>
        </ul>
      </section>
      
      <section className="mt-4">
        <h2 className="text-xl font-semibold">3. Điều khoản chung</h2>
        <ul className="list-disc pl-6">
          <li>Khi được luật pháp cho phép, chúng tôi sẽ không chịu trách nhiệm về khoản lợi nhuận, doanh thu hoặc dữ liệu bị mất mát, thiệt hại tài chính hoặc các thiệt hại gián tiếp, hậu quả từ việc bị xử phạt do các hành vi vi phạm Các điều khoản sử dụng này và vi phạm pháp luật.</li>
          <li>Chúng tôi đảm bảo chất lượng dịch vụ và bảo vệ dữ liệu người dùng.</li>
          <li>Chúng tôi có quyền chặn, xóa nội dung vi phạm hoặc khóa tài khoản nếu phát hiện hành vi vi phạm.</li>
          <li>Chúng Tôi có quyền chấm dứt hoặc tạm ngừng cung cấp dịch vụ mà không cần thông báo trước trong một số trường hợp cần thiết. Người dùng có thể ngừng sử dụng dịch vụ bất kỳ lúc nào.</li>
          <li>Chúng Tôi không chịu trách nhiệm về các tổn thất gián tiếp, ngẫu nhiên hoặc do người dùng tự gây ra. Dịch vụ có thể bị gián đoạn do bảo trì hoặc các sự cố ngoài tầm kiểm soát.</li>
          <li>Nội dung do LinkLearn.vn hoặc người dùng tạo ra đều thuộc quyền sở hữu của tác giả hoặc nền tảng. Người dùng không được sao chép, phân phối hoặc sử dụng nội dung từ nền tảng cho mục đích thương mại mà không có sự cho phép.</li>
          <li>Chúng tôi không chịu trách nhiệm với bất kỳ tổn thất nào do người dùng vi phạm điều khoản hoặc lỗi hệ thống ngoài ý muốn.</li>
          <li>Chúng tôi có thể sửa đổi nội dung trong Điều khoản này hoặc bất kỳ điều khoản bổ sung áp dụng cho một Sản phẩm để phản ánh những thay đổi về luật hoặc những thay đổi về Sản phẩm của chúng tôi. Bạn nên thường xuyên chủ động tìm hiểu các điều khoản này. Chúng tôi sẽ đăng thông báo về các điều khoản bổ sung đã được sửa đổi trong Sản phẩm được áp dụng trên trang. Các thay đổi sẽ không áp dụng hồi tố và sẽ có hiệu lực không sớm hơn mười bốn ngày sau khi thay đổi đó được đăng. Tuy nhiên, những thay đổi liên quan đến chức năng mới của Sản phẩm hoặc các thay đổi được thực hiện vì lý do pháp lý sẽ có hiệu lực ngay lập tức. Nếu bạn không đồng ý với các điều khoản sửa đổi đối với một Sản phẩm nào đó, bạn nên ngừng sử dụng Sản phẩm đó.</li>
          <li>Nếu có sự mâu thuẫn giữa các điều khoản này và các điều khoản bổ sung, thì các điều khoản bổ sung sẽ được ưu tiên áp dụng đối với trường hợp mâu thuẫn đó.</li>
          <li>Điều khoản này được xây dựng dựa trên pháp luật Việt Nam, mọi vấn đề pháp lý phát sinh từ Điều khoản này được điều chỉnh theo pháp luật Việt Nam. Trường hợp có tranh chấp pháp lý phát sinh từ Điều khoản này, hai Bên đầu tiên sẽ thương lượng, hòa giải trên tinh thần thiện chí, hợp tác. Nếu tranh chấp không giải quyết được bằng thương lượng, hòa giải thì hai bên nhất trí đưa vụ việc ra Tòa án giải quyết, phán quyết của Tòa án là quyết định cuối cùng buộc hai bên phải thực hiện. Bên thua kiện sẽ phải chịu án phí và các chi phí khác có liên quan trong quá trình giải quyết tranh chấp.</li>
          </ul>
      </section>
      
      <section className="mt-4">
        <h2 className="text-xl font-semibold">4. Thanh Toán và Hoàn Tiền</h2>
        <ul className="list-disc pl-6">
          <li>Một số dịch vụ trên LinkLearn.vn có thể yêu cầu thanh toán.</li>
          <li>Dựa vào chính sách cước phí của Chúng Tôi, bạn đồng ý trả phí Sản phẩm Chúng Tôi bao gồm các chi phí để khởi tạo và vận hành Sản phẩm.</li>
          <li>Các khoản thanh toán không được hoàn lại trừ khi có quy định cụ thể hoặc lỗi từ hệ thống.</li>
        </ul>
      </section>

      <section className="mt-4">
        <h2 className="text-xl font-semibold">5. Liên Hệ</h2>
        <p>Nếu có bất kỳ câu hỏi nào về Điều khoản Sử dụng, vui lòng liên hệ:</p>
        <ul className="list-disc pl-6">
          <li><strong>Email:</strong> <a href="mailto:linklearn.hotro@gmail.com" className="text-blue-600">linklearn.hotro@gmail.com</a></li>
          <li><strong>Hotline:</strong> 0837914479</li>
        </ul>
      </section>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
  }
}
