
import React from 'react'
import { useHistory } from 'react-router';
import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {LOGIN, SIGNUP, LOGIN_WITH_GOOGLE} from '../../graphql-client/Mutation';
import  JWTManager from '../../utils/jwt.js';
import { useAuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';
export default function Login() {
  const history = useHistory();
  const {setIsAuthenticated} = useAuthContext()
  const [formState, setFormState] = useState({
    login: true,
    email: '',
    password: '',
    repeatPassword:'',
    name: '',
    tokenLoginWithGoogle:''
  });
  const [savePassword, setSavePassword] = useState(false);

  const toggleSavePassword = () => {
    setSavePassword(!savePassword);
  }
  
  const [login,{error: errorLogin}] = useMutation(LOGIN, {
    variables: {
      email: formState.email,
      password: formState.password
    },
    onCompleted: ({ login }) => {
      JWTManager.setToken(login.token);
      setIsAuthenticated(true)
      history.push('/');
    }
  });
  // if (errorLogin) {
  //   alert('Email hoặc mật khẩu không đúng');
  // }
  const [loginWithGoogle,{error: errorLoginWithGoogle}] = useMutation(LOGIN_WITH_GOOGLE, {
    variables: {
      jwtToken: formState.tokenLoginWithGoogle
    },
    onCompleted: ({ loginWithGoogle }) => {
      JWTManager.setToken(loginWithGoogle.token);
      setIsAuthenticated(true)
      history.push('/');
    }
  });
  const [signup,{error: errorSignup}] = useMutation(SIGNUP, {
    variables: {
      name: formState.name,
      email: formState.email,
      password: formState.password
    },
    onCompleted: ({ signup }) => {
      JWTManager.setToken(signup.token);
      setIsAuthenticated(true)
      history.push('/');
    }
  });

  const signupWithGG = ()=>{
    alert('Hiện tại Linklearn.vn đã tích hợp tạo tài khoản mới nhanh chóng bằng việc đăng nhập bằng Google. Hãy sử dụng chức năng đăng nhập bằng Google, Linlearn.vn sẽ nhận thông tin từ API google để tạo tài khoản mới tương thích với tài khoản Google của Bạn.')
    // handleCallbackResponse()
  }

  function handleCallbackResponse (response){
      setFormState({...formState,tokenLoginWithGoogle:response.credential});
      loginWithGoogle()
    }
  
  useEffect(() => {
    /* global google */
    if (window.google) {
        google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCallbackResponse,
        });

        google.accounts.id.renderButton(
          document.getElementById("singInDiv"), 
          {
            theme: "outline", size: "large"
          }
        );
      }
    }, []);
        
 const handleSubmit = (e)=>{
    e.preventDefault();
    
    if(!formState.login && formState.password !== formState.repeatPassword){
      return alert('Mật khẩu không khớp');
    }
    // formState.login ? login() : signupWithGG()
    if (formState.login) {
      login();
    } else {
      signupWithGG();
    }
  }
  
  return (
    <section className="vh-100 bg-image mt-5 mb-3 "
        style={{backgroundImage: "url('/background.png')"}}
    >
      <div className="mask d-flex align-items-center h-100 gradient-custom-3">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-10 col-md-9 col-lg-7 col-xl-6">
              <div className="card" style={{borderRadius: '15px'}}>
                <div className="card-body p-5">
                  {
                    formState.login
                    ?<h2 className="text-uppercase text-center mb-5">Đăng nhập</h2>
                    :<h2 className="text-uppercase text-center mb-5">Tạo tài Khoản</h2>
                  }
                  { !formState.login&& <p className='alert alert-success'>Hiện tại Linklearn.vn đã tích hợp tính năng tạo tài khoản mới
                     nhanh chóng bằng việc đăng nhập bằng Google. Hãy sử dụng chức năng đăng nhập bằng Google trên trình duyệt của bạn, 
                    Linlearn.vn sẽ nhận thông tin từ API Google để tạo tài khoản mới tương thích với tài khoản email Google của Bạn.</p>}
                  <form onSubmit={handleSubmit}>
                    {!formState.login &&
                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="formLoginName">Tên của bạn</label>
                        <input 
                          value={formState.name}
                          onChange={(e) =>
                              setFormState({
                              ...formState,
                              name: e.target.value
                              })
                          }
                          placeholder="Nhập vào tên của bạn"
                        type="text" 
                        id="formLoginName" 
                        className="form-control" 
                        />
                      </div>
                      }
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="formLoginEmail"
                      >
                       Email
                      </label>
                      <input 
                        value={formState.email}
                        onChange={(e) =>
                            setFormState({
                            ...formState,
                            email: e.target.value
                            })
                        }
                        placeholder="Ví dụ: name@gmail.com"
                        autoComplete={savePassword ? "on" : "off"} 
                        // type="text" 
                        type="email" 
                        id="formLoginEmail" 
                        className="form-control " 
                      />
                      
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="formLoginPassword">Mật khẩu</label>
                      <input 
                        value={formState.password}
                        onChange={(e) =>
                            setFormState({
                            ...formState,
                            password: e.target.value
                            })
                        }
                        placeholder="Nhập vào mật khẩu"
                        autoComplete={savePassword ? "on" : "off"} 
                        type="password" 
                        id="formLoginPassword" 
                        className="form-control " 
                      />
                    </div>
                    {!formState.login &&
                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="formLoginRepeatPassword">Nhập lại mật khẩu</label>
                        <input 
                          value={formState.repeatPassword}
                          onChange={(e) =>
                              setFormState({
                              ...formState,
                              repeatPassword: e.target.value
                              })
                          }
                          placeholder="Nhập lại mật khẩu"
                          autoComplete={savePassword ? "on" : "off"} 
                          type="password" 
                          id="formLoginRepeatPassword" 
                          className="form-control " 
                        />
                        
                      </div>
                      }
                      {!formState.login &&
                        <div className="form-check d-flex flex-row mb-5">
                            <input className="form-check-input m-auto " type="checkbox" 
                            checked={savePassword}
                            onChange={toggleSavePassword}
                            id="formLoginRules" />
                            <p className='mb-0 m-auto'>Tôi đồng ý với các nội dung trong</p>  
                            <Link className='m-auto' to="/termsOfService">
                            <u className='m-auto'>
                                Điều khoản dịch vụ
                              </u>
                            </Link>
                              
                        </div>
                      }
                      {formState.login &&
                        <div className="form-check d-flex flex-row mb-5">
                          <input 
                            checked={savePassword} 
                            onChange={toggleSavePassword}
                            className="form-check-input mt-3 mb-2 " 
                            type="checkbox" 
                            id="formLoginSavePassword" 
                          />
                          <label className="form-check-label mt-1 ms-1" htmlFor="formLoginSavePassword">
                            <p className='mb-1 mt-2'>Lưu mật khẩu tài khoản </p>
                          </label>
                        </div>
                        
                      }
                     {
                        errorLogin && 
                        <div  className='mx-auto'>
                          <p className='textError'>Email hoặc mật khẩu không đúng</p>
                        </div>
                      }
                     {
                        errorLoginWithGoogle && 
                        <div  className='mx-auto'>
                          <p className='textError'>Không thể đăng nhập bằng google</p>
                        </div>
                      }
                     {
                        errorSignup && 
                        <div  className='mx-auto'>
                          <p className='textError'>Đăng ký không thành công</p>
                        </div>
                      }
                    <div className="d-flex justify-content-center">
                      <button 
                        type="submit" 
                        className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                      >
                        {formState.login?'Đăng nhập':'Đăng ký'}
                      </button>
                    </div>
                   
                  </form>
                  <div className='d-flex justify-content-between'>
                    
                    <button id='singInDiv' className='btn mt-3 mx-auto ' type='button'>Đăng nhập/Đăng ký bằng google</button>
                   
                  </div>
                  
                  <button 
                      onClick={(e) =>
                          setFormState({
                          ...formState,
                          login: !formState.login
                          })
                      }
                      className="btn text-center text-muted mt-5 mb-0"
                  >
                    {
                      formState.login
                      ?<b>
                        <u onClick={()=>setFormState({email:'', password:''})}>Tạo tài khoản</u>
                        </b>
                      :<b>Bạn đã có tài khoản? <u>Đăng nhập</u></b>
                    }
                  </button> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
