import React ,{useEffect, useState} from 'react'
import { useQuery } from '@apollo/client';
import { getDocumentVideo } from '../../../../../../graphql-client/Queries';
import { useParams } from 'react-router-dom';
import NotificationError from '../../../../../NotificationError';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { ConvertToCloudFrontUrl } from '../../../../../../utils/ConvertToCloudFrontUrl';
export default React.memo(function DocumentVideoPlaying() {
     let {id} = useParams();
     const [pdfUrl, setPdfUrl] = useState(null);
     const {loading, error, data} = useQuery(getDocumentVideo,{
         variables: {id},
     });
     useEffect(() => {
        if (data?.video?.document) {
            setPdfUrl(ConvertToCloudFrontUrl(data.video.document));
        }
    }, [data]); 
     if(loading) return <p>loading...</p>;
     if (error) return <p>Error! {error.message}</p>;
     
    return (
        <div className="tab-pane documentVideo" id="document" role="tabpanel" aria-labelledby="document-tab-document">
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download className="btn  mt-2">
                <strong>Mở trong tap mới/ Tải tài liệu về</strong>
            </a>
            <div className='card card-body d-flex-column bd-highlight border-0 row col-12 m-1'>
                {
                    pdfUrl
                    ?
                    <div className='documentPdfVideo'>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                            <Viewer fileUrl={pdfUrl} />
                        </Worker>
                    </div>
                    :
                    <NotificationError text={"Video chưa có tài liệu nào."}></NotificationError>
                    
                }
            </div>
        </div>
    )
}
)