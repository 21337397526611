import React, { Component } from 'react'
import { Link } from 'react-router-dom'
export default class FAQAccordion extends Component {
  render() {
    return (
      <div className="accordion accordion-flush" id="accordionFlushExample">
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            <strong>LinkLearn.vn là gì?</strong>
          </button>
        </h2>
        <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            LinkLearn.vn là một kho lưu trữ học liệu, siêu học liệu dưới dạng video bài giảng, file pdf giúp kết nối chuyện gia, giảng viên, giáo viên với 
            học sinh, sinh viên 
            và người học trên khắp Việt Nam tiếp cận kiến thức chuyên nghiệp và dễ dàng.
          </div>
        </div>
      </div>
    
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            <strong>Làm sao để tham gia giảng dạy trên LinkLearn.vn?</strong>
          </button>
        </h2>
        <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            Bạn có thể đăng ký làm giảng viên qua mục "Đăng ký" trên trang chủ và điền đầy đủ thông tin theo hướng dẫn.
          </div>
        </div>
      </div>
    
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            <strong>LinkLearn.vn có mất phí không?</strong>
          </button>
        </h2>
        <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            Linklearn miễn phí cho việc xem và tải tài liệu pdf, với tài liệu là video Bạn sẽ được miễn phí 2 giờ học video mỗi tháng và 10 GB dung lượng tải lên mỗi tháng,
            sau khi sử dụng hết bạn có thể mua thêm tại: &nbsp;
            <Link to="/subscribePackage">Đăng Ký gói cước</Link>
          </div>
        </div>
      </div>
    
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
            <strong>LinkLearn.vn có cho phép upload tài liệu lên hệ thống không?</strong>
          </button>
        </h2>
        <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            Bạn có thể upload tài liệu sau khi đăng nhập và được cấp quyền giảng viên.
          </div>
        </div>
      </div>
    
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
            <strong>Khi sử dụng sản phẩm LinkLearn.vn, số lượng người dùng có bị giới hạn không?</strong>
          </button>
        </h2>
        <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            Không, LinkLearn.vn giúp kết nối giữa giáo viên và học sinh không giới hạn số lượng, khoảng cách, thời gian!
          </div>
        </div>
      </div>
    
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
            <strong>Giáo viên dạy tự do có sử dụng được phần mềm LinkLearn.vn không?</strong>
          </button>
        </h2>
        <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            Có, giáo viên tự do có thể tham gia giảng dạy bằng cách đăng ký trên hệ thống.
          </div>
        </div>
      </div>
    
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
            <strong>Tôi nhận được sự hỗ trợ như thế nào khi sử dụng hệ thống LinkLearn.vn?</strong>
          </button>
        </h2>
        <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
          Đội ngũ Tư vấn và chăm sóc khách hàng của chúng tôi luôn sẵn sàng hỗ trợ và 
          giải quyết vấn đề liên quan đến hệ thống, kỹ thuật và công nghệ Đội kỹ thuật 
          sẽ sắp xếp xử lý trục trặc và vấn đề của thầy cô một cách nhanh nhất 
          để không bị ảnh hưởng đến quá trình giảng dạy, học tập.
          </div>
        </div>
      </div>
    
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
            <strong>Nếu tôi gặp vấn đề trong quá trình sử dụng, tôi có thể liên hệ với ai và phương thức liên lạc là gì?</strong>
          </button>
        </h2>
        <div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            Quý thầy cô, học sinh có bất cứ câu hỏi nào về sản phẩm 
            và nội dung liên quan đến truy cập sử dụng, hãy liên lạc với chúng tôi
             theo các phương thức dưới đây: 
              <br />
              <strong>Fanpage:</strong> <a href="https://www.facebook.com/profile.php?id=61566917694888" target="_blank" rel="noopener noreferrer">Linklearn.vn </a>
              <br />
              <strong>Zalo:</strong> 0837914479
              <br />
              <strong>Email hỗ trợ:</strong> linklearn.hotro@gmail.com
            <br />
          </div>
        </div>
      </div>
    
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
            <strong>Quy trình tư vấn triển khai và chăm sóc hỗ trợ của LinkLearn.vn như thế nào?</strong>
          </button>
        </h2>
        <div id="flush-collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            <strong className='card-title'>
            Chúng tôi cung cấp tư vấn triển khai và hỗ trợ sau triển khai với các 
            kênh liên lạc trực tuyến nhanh chóng với 3 bước sau:
            </strong>
            <div className='card-body'>
              <strong>
              1. Tiếp nhận thông tin cần hỗ trợ:&nbsp;
              </strong>
               Đại diện Linklearn tiếp nhận thông tin 
            từ khách hàng trên các kênh Fanpage, Hotline, Email,...
            <br /> 
            <strong>
            2. Tổng hợp, sắp xếp, giải đáp các thông tin:&nbsp;
            </strong>
            Đội ngũ LinkLearn tổng hợp, 
            giải đáp các thông tin và truyền tải đến đội ngũ chăm sóc
            khách hàng để tiến hành hỗ trợ.
            
            <br />
            <strong>
            3. Triển khai hỗ trợ khách hàng:&nbsp;
            </strong>
            Đội ngũ LinkLearn và khách hàng sẽ trao đổi 
            thông tin (khi sử dụng hệ thống, các vấn đề cần làm rõ,..) 
            chúng tôi sẽ hướng dẫn khách hàng sử dụng sản phẩm theo 
            đúng chức năng và nhu cầu.
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
              <strong>Tốc độ bit video là gì?</strong>
            </button>
          </h2>
          <div id="flush-collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              Tốc độ bit (bitrate) của video là lượng dữ liệu được sử dụng để mã hóa video trong một giây, thường được đo bằng Kbps (kilobits per second) hoặc Mbps (megabits per second).  
              Tốc độ bit cao hơn thường đồng nghĩa với chất lượng hình ảnh tốt hơn, nhưng cũng làm tăng dung lượng tệp video. Khi tải video lên bạn nên để tốc độ bit phù hợp để không sảy ra giật lag mà chất lượng vẫn đảm bảo
              <br />
              Đối với video HD 720p, tốc độ bit phù hợp thường là từ 3-5 Mbps.  
              Đối với video Full HD 1080p, tốc độ bit từ 5-8 Mbps sẽ cho chất lượng tốt <strong>(linnklearn khuyến cáo để mức tối đa 6000 kbps hoặc 6 Mbps)</strong>.  
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
              <strong>Điều chỉnh tốc độ bit của video ở đâu?</strong>
            </button>
          </h2>
          <div id="flush-collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              Bạn có thể điều chỉnh tốc độ bit của video ngay khi quay trên thiết bị quay video bằng cách chọn chế độ quay phù hợp hoặc
              sau khi quay bằng cách sử dụng các phần mềm chỉnh sửa video như capcut (miễn phí), Adobe Premiere , Final Cut Pro, HandBrake hoặc FFmpeg (miễn phí).  
              Khi xuất video, hãy tìm tùy chọn "bitrate" hoặc "quality settings" để đặt mức bitrate phù hợp với nhu cầu của bạn.
            </div>
          </div>
        </div>
    </div>
    
    )
  }
}
