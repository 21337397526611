// export function ConvertToCloudFrontUrl(s3Url) {
//     if (!s3Url) {
//         return '/default-avatar.png'; // Trả về chuỗi rỗng nếu s3Url không tồn tại
//     }
//     const s3BaseUrl = 'https://s3.ap-southeast-1.amazonaws.com/dbs3.learnlink';
//     const cdnBaseUrl = process.env.REACT_APP_DOMAINE_CDN_S3;

//     if (s3Url.startsWith(s3BaseUrl)) {
//         return s3Url.replace(s3BaseUrl, cdnBaseUrl);
//     }
//     return s3Url; // Trả về URL gốc nếu không có chuỗi cần thay thế
// }
export function ConvertToCloudFrontUrl(fileUrl) {
    if (!fileUrl) {
        return '/default-avatar.png'; // Nếu không có URL, trả về ảnh mặc định
    }

    // Lấy thông tin từ env để linh hoạt hơn
    const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME ;
    const S3_REGION = process.env.REACT_APP_S3_REGION ;
    const S3_BASE_URL = `https://s3.${S3_REGION}.amazonaws.com/${S3_BUCKET_NAME}`;
    const S3_CDN_URL = process.env.REACT_APP_CDN_S3 || '';

    // const B2_BUCKET_NAME = process.env.REACT_APP_B2_BUCKET_NAME || 'linklearn';
    // const B2_ENDPOINT = process.env.REACT_APP_B2_ENDPOINT || 'https://s3.us-west-004.backblazeb2.com';
    const B2_BASE_URL = process.env.REACT_APP_B2_BASE_URL;
    const B2_CDN_URL = process.env.REACT_APP_CDN_B2 ;

    // const R2_BUCKET_NAME = process.env.REACT_APP_R2_BUCKET_NAME || 'data.linklearn.vn';
    const R2_BASE_URL = process.env.REACT_APP_R2_BASE_URL;
    const R2_CDN_URL = process.env.REACT_APP_CDN_R2;

    if (fileUrl.startsWith(S3_BASE_URL) && S3_CDN_URL) {
        return fileUrl.replace(S3_BASE_URL, S3_CDN_URL);
    }

    if (fileUrl.startsWith(B2_BASE_URL) && B2_CDN_URL) {
        return fileUrl.replace(B2_BASE_URL, B2_CDN_URL);
    }

    if (fileUrl.startsWith(R2_BASE_URL) && R2_CDN_URL) {
        return fileUrl.replace(R2_BASE_URL, R2_CDN_URL);
    }

    return fileUrl; // Nếu không khớp dịch vụ nào, giữ nguyên URL gốc
}