import {  useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'
import { getCountUserSubscribedTo } from '../../../graphql-client/Queries'
import {UPDATE_SUBSCRIBED} from '../../../graphql-client/notification/Notification'
import ButtonSubUser from './ButtonSubUser'
export default function TopUser() {
    let {idUser}=useParams()
    const [modalVisible] = React.useState(false)
    
    const{loading, error, data, subscribeToMore}= useQuery(getCountUserSubscribedTo,{
        variables: {idUser}   
    })


    
    console.log(data)
    const avatarUser = data && data.user.avatar? data.user.avatar : "/default-avatar.png"
    const coverUser = data && data.user.cover? data.user.cover : "/default-cover.jpg"
    React.useEffect(() => {
        let unsubscribe
        if (!modalVisible) {
            unsubscribe = subscribeToMore({
                document: UPDATE_SUBSCRIBED,
                variables: {userId : idUser},
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;
                    const newUser = subscriptionData.data.newUserSubscription.subscribedTo;
                    return Object.assign({}, prev, {
                            user: newUser,
                        });
                }
            });
        }
        if (unsubscribe) return () => unsubscribe()
    }, [modalVisible, subscribeToMore, idUser])

    
    if(loading) return <p>loading...</p>
    if(error) return <p>error</p>
    
    return (
        <div>
            <div className=" border d-flex flex-column">
                <img 
                    className="imgCover mx-auto"  
                    src={coverUser } 
                    alt={'ảnh bìa của người dùng '+data.user.username}
                />
                <img 
                    className="avatar mx-auto rounded-circle" 
                    src={avatarUser} 
                    alt={'ảnh đại diện của người dùng '+data.user.username}
                />
                <h5 className=" mx-auto mt-2" >
                    {data.user.username}
                </h5>
            </div>
            <div className=" d-flex flex-column subsUser">
                <ButtonSubUser></ButtonSubUser>
                <div className="d-flex mx-auto">
                    <p className='me-1'>
                        {data.user.countSubscribedTo}
                    </p>
                    <div>•</div>
                    <p className='ms-1'>
                        Người đăng ký
                        
                    </p>
                </div>
            </div>
        </div>
    )
}
